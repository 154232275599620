let _timestampInit = (new Date).getTime();

import Vue from 'vue';
import App from '@/App.vue';
import VueRouter from 'vue-router';

import i18n from '@/i18n';
import vco from "v-click-outside";
import Vuelidate from 'vuelidate';
import BootstrapVue from 'bootstrap-vue';
import VueToast from 'vue-toast-notification';
import VueSweetalert2 from 'vue-sweetalert2';
import VueApexCharts from 'vue-apexcharts';
import VueMask from 'v-mask';

import FlagIcon from 'vue-flag-icon'
import vueCountryRegionSelect from 'vue-country-region-select';
import io from 'socket.io-client';
import VueSocketIOExt from 'vue-socket.io-extended';
import VueSanitize from "vue-sanitize";
import store from '@/state/store';
import router from '@/router/index';
import { gameToGameIcon, gameToGameIconLarge, gameToTitle, parseISO8601String, gameIsSteamTitle } from '@/methods';
//import SessionLoginState from '@/state/helpers'
import ToggleButton from 'vue-js-toggle-button'

import TablePagination from '@/components/shared/TablePagination.vue';

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

Vue.mixin({
    methods: {
        parseDate: parseISO8601String,
        gameIcon: gameToGameIcon,
        gameIconLarge: gameToGameIconLarge,
        gameToTitle: gameToTitle,
        isSteamTitle: gameIsSteamTitle
    },
})

import "@/design/index.scss";
import 'leaflet/dist/leaflet.css';
import { SessionLoginState } from "@/enums";

const socket = io(process.env.VUE_APP_WS_HOST, {
    path: '/connect',
    transports: ['websocket']
});
Vue.use(VueSocketIOExt, socket);

Vue.config.productionTip = false;
Vue.config.ignoredElements = [
    "c-content"
]

Vue.use(ToggleButton) //

Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.use(Vuelidate);
Vue.use(FlagIcon);
Vue.use(vco);
Vue.use(VueToast, {
    position: 'bottom-left',
    duration: 1500,
    dismissible: true,
    pauseOnHover: true
});
Vue.use(VueSweetalert2);
Vue.use(VueMask);
Vue.use(require('vue-chartist'));
Vue.component('apexchart', VueApexCharts);
Vue.use(vueCountryRegionSelect);
let defaultOptions = {
    allowedTags: ['b', 'br'],
    allowedAttributes: {}
};
Vue.use(VueSanitize, defaultOptions);

Vue.use(TablePagination);

import ServerWidget from "@/components/ServerWidget";

Vue.component('ServerWidget', ServerWidget);

/* *** Filter *** */
var filter = function (text, length, clamp) {
    clamp = clamp || '...';
    var node = document.createElement('div');
    node.innerHTML = text;
    var content = node.textContent;
    return content.length > length ? content.slice(0, length) + clamp : content;
};
Vue.filter('truncate', filter);
/* *** ***** *** */

/* *** Prototypes *** */
Number.prototype.pad = function (size) {
    var s = String(this);
    while (s.length < (size || 2)) {
        s = "0" + s;
    }
    return s;
}
/* ** *********** *** */

Vue.prototype.$applicationEnvironment = process.env.NODE_ENV;


/* ***************** */

/* TURNSTILE */

async function turnstileCallback(token) {
    const payload = {
        token: token
    };

    let url = new URL(process.env.VUE_APP_ROOT_API + `v1/@me/clearance`);
    let response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(payload),
        credentials: 'include'
    });
}
async function clearanceCycle(busy = false) {
    const clearanceElement = document.getElementById('cfcloud-clearance');
    if(!clearanceElement) {
        const root = document.getElementById('cfcloud-body');
        const turnstileElement = document.createElement('div');
        turnstileElement.id = 'cfcloud-clearance'
        turnstileElement.classList.add('cf-turnstile');
        root.appendChild(turnstileElement);

        const tmp = document.getElementById('cfcloud-clearance');
        window.turnstile.render(tmp, {
            sitekey: '0x4AAAAAAAaaRryWy0vXRh0N',
            callback: turnstileCallback
        });
    } else {
        const clearanceElement = document.getElementById('cfcloud-clearance');
        window.turnstile.reset(clearanceElement);
    }
}
async function intiateClearance() {
    if(window.turnstile) {
        await clearanceCycle();
        setInterval(clearanceCycle, 1000 * 60 * 10);
    } else {
        setTimeout(intiateClearance, 50);
    }
}
intiateClearance();

/* ********* */

let ws_times = [];
let ws_time;
let sessionExpiration;

fetch(process.env.VUE_APP_ROOT_API + 'v1/@me/status', { credentials: 'include' })
    .then(response => {
        if (response.ok) {
            return response.json();
        } else {
            console.error(`[CRITICAL] Failed to connect core API: (${response.status})${response.statusText}`);
        }
    })
    .then(data => {
        sessionExpiration = parseISO8601String(data.session.expires_at);
        setTimeout(() => {
            if (new Date() >= sessionExpiration) {
                location.reload();
            }
        }, 60000);
        store.commit('setSessionExpiration', sessionExpiration);
        store.commit('setLoginState', data.state.login);

        if (store.getters.getLoginState() !== SessionLoginState.LOGGED_IN) {
            // Normal case for not logged in user
            location.replace(process.env.VUE_APP_AUTH_PROVIDER);
            return;
        } else if (!data.user.authenticated || !data.user.logged) {
            // Should the session state be manipulated
            location.replace(process.env.VUE_APP_AUTH_PROVIDER);
            return;
        }

        store.commit('setAccountId', data.user.cftools_id);

        fetch(process.env.VUE_APP_ROOT_API + 'v1/@me/bootstrap', { credentials: 'include' })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    console.error(`[CRITICAL] Failed to connect core API: (${response.status})${response.statusText}`);
                    if (response.status === 401) {
                        // NOT LOGGED IN
                        location.replace(process.env.VUE_APP_AUTH_PROVIDER);
                    }
                }
            })
            .then(async data => {
                if (store.getters.getAccountId() !== data.persona.cftools_id) {
                    throw new Error('Invalid account state');
                }

                let progression = data.progression;
                //store.commit('setModals', progression.modals); // TODO: Reactivate for new feature launch
                store.commit('setEntryPath', window.location.pathname);

                await store.dispatch('updateData', data);

                if (process.env.NODE_ENV === 'production') {
                    Sentry.init({
                        Vue,
                        dsn: "https://e0c50142d40b59a046d52fe0c8b5aa39@sentry.cftools.cloud/2",
                        integrations: [
                            new BrowserTracing({
                                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                                tracePropagationTargets: ["app.cftools.cloud", /^\//],
                            }),
                        ],
                        tracesSampleRate: 1.0,
                    });
                }

                /* Initialize main Vue instance */
                let vue_instance = new Vue({
                    router,
                    store,
                    i18n,
                    fallbackLocale: 'en',
                    render: h => h(App),
                    data() {
                        return {
                            firstClearance: false
                        };
                    },
                    sockets: {
                        connect() {
                            console.log(`[WS] UP`);
                            //setInterval(function () {
                            //  ws_time = (new Date).getTime();
                            //  socket.emit('keepalive');
                            //}, 60000);
                        },
                        disconnect() {
                            console.log(`[WS] DOWN`);
                        },
                        ack(data) {
                            console.log(`[WS] Connected to ${data.id}(${data.v})@${data.s}`);
                        },
                        keepalive() {
                            var latency = (new Date).getTime() - ws_time;
                            ws_times.push(latency);
                            ws_times = ws_times.slice(-30);
                            var sum = 0;
                            for (var i = 0; i < ws_times.length; i++)
                                sum += ws_times[i];

                            var avg_latency = Math.round(10 * sum / ws_times.length) / 10;
                            if (latency > 250.0) {
                                console.log(`[WS] (Warning) latency: ${latency}ms, avg-latency: ${avg_latency}ms`);
                            }
                            socket.latency = avg_latency;
                        },
                        refresh() {
                            console.log(`[WS] Refreshing...`);
                            socket.emit('refresh');
                        },
                        update() {
                            console.log(`[WS] Updating resources...`);
                            this.updateResources();
                            console.log(`[WS] Updated`);
                            socket.emit('refresh');
                        }
                    },
                    created: function () {
                        let loadingTime = (new Date).getTime() - _timestampInit;
                        console.log(`Initialized in ${loadingTime}ms`);
                    },
                    methods: {
                        async updateResources() {
                            await this.update();
                        },
                        async update() {
                            let response = await fetch(process.env.VUE_APP_ROOT_API + 'v1/@me/bootstrap', { credentials: 'include' });
                            if (response.ok) {
                                let data = await response.json();

                                if (store.getters.getAccountId() !== data.persona.cftools_id) {
                                    location.reload();
                                    throw new Error('Invalid account state');
                                }

                                await store.dispatch('updateData', data);
                            }
                        }

                    },
                    mounted() {
                        this.$socket.$subscribe('omega:deploy', function () {
                        }); // Dummy global handler
                        this.$socket.$subscribe('presence:server', function () {
                        }); // Dummy global handler
                        this.$socket.$subscribe('user:bootstraped', function () {
                            console.log(`[WS] New tab opened`);
                        });
                        this.$socket.$subscribe('update:server', function (event) {
                            let server_id = event.server_id;

                            const data = event;
                            delete data['server_id'];
                            if (process.env.NODE_ENV === 'development') {
                                console.log(`[WS] Received update for server ${server_id}`);
                            }
                            store.commit('setServer', { server_id, data });
                        });
                        this.$socket.$subscribe('gsm:player:create', function (event) {
                            let server_id = event.server.id;
                            if (process.env.NODE_ENV === 'development') {
                                console.log(`[WS] (GSM) session created for ${server_id}`);
                            }

                            let server = store.getters.getServer(server_id);
                            const data = {
                                status: Object.assign({}, server.status)
                            };
                            data.status.players++;
                            data.status.players = Math.min(data.status.players, data.status.slots);

                            store.commit('setServer', { server_id, data });
                        });
                        this.$socket.$subscribe('gsm:player:persona', function () {
                        }); // Dummy global handler
                        this.$socket.$subscribe('gsm:player:destruct', function (event) {
                            let server_id = event.server.id;
                            if (process.env.NODE_ENV === 'development') {
                                console.log(`[WS] (GSM) session destruct for ${server_id}`);
                            }

                            let server = store.getters.getServer(server_id);
                            const data = {
                                status: Object.assign({}, server.status)
                            };
                            data.status.players--;
                            data.status.players = Math.max(data.status.players, 0);

                            store.commit('setServer', { server_id, data });
                        });
                        this.$socket.$subscribe('server:feed', function () {
                        }); // Dummy global handler
                        this.$socket.$subscribe('server:metrics', function () {
                        }); // Dummy global handler
                        this.$socket.$subscribe('server:update', function (event) {
                            let server_id = event.server_id;
                            if (process.env.NODE_ENV === 'development') {
                                console.log(`[WS] Received update from server ${server_id}`);
                            }

                            if (event.type === 'server') {
                                const data = {
                                    status: {
                                        players: event.server.population.players,
                                        slots: event.server.population.slots,
                                        queue: {
                                            active: (event.server.population.queue > 0),
                                            size: event.server.population.queue
                                        },
                                        next_restart: event.server.environment.next_restart.local,
                                        uptime: event.server.environment.uptime,
                                        environment: event.server.environment
                                    }
                                };
                                store.commit('setServer', { server_id, data });
                            }
                        });
                        this.$socket.$subscribe('server:state', function (event) {
                            let server_id = event.server.id;
                            let state = event.state.state;
                            let stateString = event.state.string;
                            if (process.env.NODE_ENV === 'development') {
                                console.log(`[WS] Server ${server_id} is now in ${stateString}`);
                            }
                            const data = {
                                state: state,
                                online: !(event.state.offline)
                            };
                            store.commit('setServer', { server_id, data });
                        });
                        let ref = this;
                        this.$socket.$subscribe('update', function () {
                        }); // Dummy global handler
                        this.$socket.$subscribe('event_subscription', function (event) {
                            console.log(`[WS] Subscription status changed`);
                            ref.updateResources();
                            console.log(`[WS] Updated`);
                            socket.emit('refresh');
                        });
                    },
                    watch: {
                        '$route'(to, from) {
                            store.commit('setRoute', to.path);
                        }
                    },
                });
                vue_instance.$mount('#app');

                let next = data.redirect.to ? data.redirect.to : window.location.pathname;
                if (progression.flags._upgrade) {
                    vue_instance.$router.push({ name: 'upgrade', params: { next: next } });
                } else if (data.redirect.to) {
                    vue_instance.$router.push(data.redirect.to);
                }
            }).catch(error => {
            console.error(`[ERROR] "${error}"`);
            //alert('Sorry, we have encountered an issue serving this request. Please refresh the page!');
            Vue.swal({
                icon: 'error',
                text: 'Sorry, we have encountered an issue serving this request (E:1)'
            }).then(function () {
                location.reload();
            });
        });
    })
    .catch(error => {
        console.error(`[ERROR] "${error}"`);
        //alert('Sorry, we have encountered an issue serving this request. Please refresh the page!');

        fetch('https://cftools.tech/api/workers/ui-status')
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    console.error(`[CRITICAL] Failed to retrieve maintenance status: (${response.status})${response.statusText}`);
                }
            }).then(data => {
            if (data.maintenance) {
                location.replace('https://status.cftools.cloud/maintenance');
                Vue.swal({
                    icon: 'info',
                    title: 'Maintenance (0x03)',
                    text: 'We are currently performing maintenance on our systems. Please check back later.',
                });
            } else {
                fetch('https://api.cftools.cloud/app/v1/clock').then(response => {
                        if(response.ok) {
                            Vue.swal({
                                icon: 'error',
                                text: 'We are currently unable to serve your request',
                            }).then(function () {
                                location.reload();
                            });
                        } else {
                            if(response.status === 429) {
                                Vue.swal({
                                    icon: 'warning',
                                    title: 'Rate Limit (0x09)',
                                    text: 'You have been rate limited. Please try again later.',
                                }).then(function () {
                                    location.reload();
                                });
                            } else {
                                Vue.swal({
                                    icon: 'error',
                                    title: 'Error (0x02)',
                                    text: 'We are currently experiencing technical difficulties. Please try again.',
                                }).then(function () {
                                    location.reload();
                                });
                            }
                        }
                    }).catch(error => {
                        console.error(`[ERROR] "${error}"`);
                        Vue.swal({
                            icon: 'error',
                            title: 'Error (0x05)',
                            text: 'We are currently experiencing technical difficulties. Please try again.',
                        }).then(function () {
                            location.reload();
                        });
                    });
            }
        }).catch(error => {
            console.error(`[ERROR] "${error}"`);

            Vue.swal({
                icon: 'error',
                title: 'Error (0x01)',
                text: 'You are offline or not fully connected to the internet.',
            }).then(function () {
                location.reload();
            });
        });
    });

