export async function get_acsrf_token() {
    let response = await fetch(process.env.VUE_APP_ROOT_API + 'v1/@me/acsrf-token', { credentials: 'include' });
    let data = await response.json();
    return data.acsrf_token;
}

export function with_acrf_token(f) {
    return new Promise((resolve, reject) => {
        fetch(process.env.VUE_APP_ROOT_API + 'v1/@me/acsrf-token', { credentials: 'include' })
            .then(response => {
                if (response.ok) {
                    resolve(response.json());
                } else {
                    throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
                }
            })
            .catch(() => reject)
    });
}

export function parseISO8601String(dateString) {
    var timebits = /^([0-9]{4})-([0-9]{2})-([0-9]{2})T([0-9]{2}):([0-9]{2})(?::([0-9]*)(\.[0-9]*)?)?(?:([+-])([0-9]{2})([0-9]{2}))?/;
    var m = timebits.exec(dateString);
    var resultDate;
    if (m) {
        var utcdate = Date.UTC(parseInt(m[1]),
            parseInt(m[2]) - 1, // months are zero-offset (!)
            parseInt(m[3]),
            parseInt(m[4]), parseInt(m[5]), // hh:mm
            (m[6] && parseInt(m[6]) || 0),  // optional seconds
            (m[7] && parseFloat(m[7]) * 1000) || 0); // optional fraction
        // utcdate is milliseconds since the epoch
        if (m[9] && m[10]) {
            var offsetMinutes = parseInt(m[9]) * 60 + parseInt(m[10]);
            utcdate += (m[8] === '+' ? -1 : +1) * offsetMinutes * 60000;
        }
        resultDate = new Date(utcdate);
    } else {
        resultDate = null;
    }
    return resultDate;

    //return new Date(Date.parse(dateString));
}

export function startsWith(string, searchString, position) {
    position = position || 0;
    return string.indexOf(searchString, position) === position;
}

import { Game } from "@/enums";

export function gameToGameIcon(game) {
    switch (game) {
        case Game.DAYZ:
            return 'dayz_32x32.png';
        case Game.RUST:
            return 'rust_32x32.png';
        case Game.ARMA2:
            return 'arma2_32x32.png';
        case Game.ARMA3:
            return 'arma3_32x32.png';
        default:
            return 'default.png';
    }
}

export function gameToGameIconLarge(game) {
    switch (game) {
        case Game.DAYZ:
            return '/assets/logos/dayz.png';
        case Game.RUST:
            return '/assets/logos/rust.png';
        case Game.ARMA2:
            return '/assets/logos/arma2.png';
        case Game.ARMA3:
            return '/assets/logos/arma3.png';
        default:
            return 'default.png';
    }
}

export function gameToTitle(game) {
    switch (game) {
        case Game.DAYZ:
            return 'DayZ Standalone (PC)';
        case Game.RUST:
            return 'Rust';
        case Game.ARMA2:
            return 'ArmA 2';
        case Game.ARMA3:
            return 'ArmA 3';
        default:
            return 'Unsupported title';
    }
}

export function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
        return '0 B';
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['B', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function gameIsSteamTitle(game) {
    switch (game) {
        case Game.DAYZ:
            return true;
        case Game.RUST:
            return true;
        case Game.ARMA2:
            return true;
        case Game.ARMA3:
            return true;
        default:
            return false;
    }
}

export function parseLocalDateString(value, offset = 0) {
    const date = new Date(value)
    // date.setHours(0)
    // date.setMinutes(0)
    return new Date(+date + date.getTimezoneOffset() * 60000 + offset)
}