import store from "@/state/store";

export const menuItems = [
    {
        id: 1,
        label: "menu.dashboard.title",
        icon: "fad fa-house",
        link: "/dashboard",
        requiresSubscription: false,
    },
    /*
    TODO: Reimplement on launch date
    {
        id: 2,
        label: "menu.organizations.title",
        icon: "fad fa-building",
        _badge: () => store.getters.getOrganizationInvites().length && {
            variant: 'warning',
            text: store.getters.getOrganizationInvites().length.toString()
        },
        link: "/organizations",
        requiresSubscription: true,
        validate: () => store.getters.getCapabilities()?.organizations || store.getters.getOrganizations().length || store.getters.getOrganizationInvites().length
    },
     */
    {
        id: 3,
        label: "menu.teams.title",
        icon: "fad fa-users",
        _badge: () => store.getters.getTeamInvites().length && {
            variant: 'warning',
            text: store.getters.getTeamInvites().length.toString()
        },
        link: "/teams",
        requiresSubscription: true,
        validate: () => store.getters.getTeams().length || store.getters.getTeamInvites().length
    },
    {
        id: 4,
        label: "menu.watchers.title",
        icon: "fad fa-eye",
        link: "/watchers",
        requiresSubscription: true,
    },
    {
        id: 5,
        label: "menu.banmanager.title",
        icon: "far fa-ban",
        link: "/ban-manager",
        requiresSubscription: true
    },
    {
        id: 6,
        label: "query.title",
        icon: "fad fa-search",
        link: "/search",
        requiresSubscription: true,
    },
    /*
    {
        id: 7,
        label: "menu.computing.title",
        icon: "fad fa-server",
        link: "/compute",
        requiresSubscription: false,
    },
    */
    {
        id: 8,
        name: 'account_menu',
        label: "menu.control.title",
        icon: "$brand",
        link: "/",
        requiresSubscription: false,
        subItems: [
            {
                id: 9,
                label: "menu.account.title",
                link: "/account",
                parentId: 8
            },
            {
                id: 10,
                label: "menu.billing.title",
                link: "/billing",
                parentId: 8
            },
            {
                id: 11,
                label: "navbar.support",
                link: "/support",
                parentId: 8
            },
            {
                id: 12,
                label: "menu.profile.title",
                link: "/profile",
                parentId: 8,
                requiresSubscription: true,
            }
        ]
    },
    {
        id: 100,
        label: "menu.servers.title",
        isTitle: true,
        serversAddButton: true,
        requiresSubscription: false,
    }
];

export const accountMenu = {
    name: 'account_menu',
    label: "menu.control.title",
    icon: "$brand",
    link: "/",
    requiresSubscription: false,
    subItems: [
        {
            id: 9,
            label: "menu.account.title",
            link: "/account",
            parentId: 7,
            icon: 'fad fa-user'
        },
        {
            id: 10,
            label: "menu.billing.title",
            link: "/billing",
            parentId: 7,
            icon: 'fad fa-wallet'
        },
        {
            id: 11,
            label: "navbar.support",
            link: "/support",
            parentId: 7,
            icon: 'fad fa-map-marker-question'
        },
        {
            id: 12,
            label: "menu.profile.title",
            link: "/profile",
            parentId: 7,
            requiresSubscription: true,
            icon: 'fad fa-user-circle'
        }
    ]
};

