<script>
// import PageSelectionDropup from '@/components/shared/PageSelectionDropup.vue'

export default {
    components: {
        // PageSelectionDropup,
    },
    props: ["totalRows", "size", "perPage", "init"],
    emits: ["change"],
    data() {
        return { currentPage: 1 }
    },
    mounted() { this.currentPage = this.init },
    watch: {
        currentPage() {
            this.$emit("change", this.currentPage)
        },
    },
    methods: { pageSelector() { this.$refs.pageSelect.show() }, }
    
}
</script>
<template>
    <div class="table-pagination">
        <b-dropdown 
            ref="pageSelect"
            id="dropdown-1"
            toggle-class="pageselect-toggle"
            text="."
            dropup
            left
            >
            <b-dropdown-item
                :name="page"
                v-for="page in Math.ceil(totalRows/perPage)"
                :key="page"
                @click="currentPage=page"
            >
                {{ page }}
            </b-dropdown-item>
        </b-dropdown>
        <b-pagination :size="size" :total-rows="totalRows" v-model="currentPage" :per-page="perPage">
            <div slot="first-text" class="pagination-btn">
                <i class="far fa-arrow-to-left" />
            </div>
            <template #prev-text>
                <i class="far fa-arrow-left" />
            </template>
            <template #next-text>
                <i class="far fa-arrow-right" />
            </template>
            <template #last-text>
                <i class="far fa-arrow-to-right" />
            </template>
            <div slot="ellipsis-text" @click="pageSelector" class="page-selector">...</div>
        </b-pagination>        
    </div>
</template>

<style lang="scss">
.page-item {
    .page-link { background-color: #2e3548; }
    .page-link:hover {
        color: #0d5bdd;
        background-color: #2e3548;
    }
}

.disabled[role=presentation] {
    cursor: not-allowed !important;
    .page-link { background-color: #32394e !important; }
}

.page-item[role=separator] {
    .page-link {
        cursor: pointer !important;
        pointer-events: unset !important;
        padding: 0px !important;
        background-color: #2e3548 !important;
    }
    .page-link:hover {
        color: #0d5bdd !important;
    }
}

.pageselect-toggle {
    width: 0px !important;
    height: 0px !important;
    margin: 0px !important;
    padding: 0px !important;
    border-width: 0px !important;
}

.dropdown-menu {
    max-height: 25rem;
    overflow-y: scroll;

    &::-webkit-scrollbar { width: 5px; }
    &::-webkit-scrollbar-track { background: #2a3042; }
    &::-webkit-scrollbar-thumb {
        background: #32394e;
        border-radius: 50px;
    }
}

.page-selector {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;
    padding-right: 12px;
}
</style>