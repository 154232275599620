export const state = {
  layoutType: 'horizontal'
}

export const getters = {}

export const mutations = {

}

export const actions = {

}
