import i18n from '@/i18n';
import Vue from 'vue';
import Vuex from 'vuex';

import modules from '@/state/modules';
import { SessionLoginState } from '@/enums';

Vue.use(Vuex);

const debug = (process.env.NODE_ENV === 'development');
const store = new Vuex.Store({
    modules,
    // Enable strict mode in development to get a warning
    // when mutating state outside of a mutation.
    // https://vuex.vuejs.org/guide/strict.html
    strict: process.env.NODE_ENV !== 'production',
    state: {
        route: null,
        expiration: null,

        // Session and account
        accountName: null, // Account name
        accountId: null, // CFToolsID
        loginState: SessionLoginState.NOT_LOGGED_IN,

        // Persona
        personaName: null, // Display name
        personaAvatar: null, // Avatar url

        // User interface
        uiLanguage: null, // Language preference
        dtLocale: null,
        uiSubscriber: false, // Has ANY subscription, dictates available views etc.
        capabilities: null,
        subscriptionDetails: {},

        // Others
        roles: [], // Account roles
        teams: [],
        organizations: [],
        teamInvites: [],
        organizationsInvites: [],
        servers: [],
        memberships: [],
        resources: {},
        preferences: {},
        modals: [],

        settings: {
            menu_layout: 'default'
        }
    },
    getters: {
        getRoute: (state) => () => {
            if (debug) console.log(`[STORE] getRoute() => ${state.route}`);
            return state.route;
        },
        getSettings: (state) => () => {
            if (debug) console.log(`[STORE] getSettings() => ${JSON.stringify(state.settings)}`);
            return state.settings;
        },
        getSessionExpiration: (state) => () => {
            if (debug) console.log(`[STORE] getSessionExpiration() => ${state.expiration}`);
            return state.expiration;
        },
        getLoginState: (state) => () => {
            if (debug) console.log(`[STORE] getLoginState() => ${state.loginState}`);
            return state.loginState;
        },
        getAccountName: (state) => () => {
            if (debug) console.log(`[STORE] getAccountName() => ${state.accountName}`);
            return state.accountName;
        },
        getAccountId: (state) => () => {
            if (debug) console.log(`[STORE] getAccountId() => ${state.accountId}`);
            return state.accountId;
        },
        getPersonaName: (state) => () => {
            if (debug) console.log(`[STORE] getPersonaName() => ${state.personaName}`);
            return state.personaName;
        },
        getPersonaAvatar: (state) => () => {
            if (debug) console.log(`[STORE] getPersonaAvatar() => ${state.personaAvatar}`);
            return state.personaAvatar;
        },
        getUILanguage: (state) => () => {
            if (debug) console.log(`[STORE] getUILanguage() => ${state.uiLanguage}`);
            return state.uiLanguage;
        },
        getDTLocale: (state) => () => {
            if (debug) console.log(`[STORE] getDTLocale() => ${state.dtLocale}`);
            return state.dtLocale;
        },
        getPreferences: (state) => () => {
            if (debug) console.log(`[STORE] getPreferences() => ${JSON.stringify(state.preferences)}`);
            return state.preferences;
        },
        getSubscriberStatus: (state) => () => {
            if (debug) console.log(`[STORE] getSubscriberStatus() => ${state.uiSubscriber}`);
            return state.uiSubscriber;
        },
        getCapabilities: (state) => () => {
            if (debug) console.log(`[STORE] getCapabilities() => ${JSON.stringify(state.capabilities)}`);
            return state.capabilities;
        },
        getSubscriberDetails: (state) => () => {
            if (debug) console.log(`[STORE] getSubscriberDetails() => ${JSON.stringify(state.subscriptionDetails)}`);
            return state.subscriptionDetails;
        },
        getRoles: (state) => () => {
            // Account roles; Different to team based roles
            if (debug) console.log(`[STORE] getRoles() => ${JSON.stringify(state.roles)}`);
            return state.roles;
        },
        getTeams: (state) => () => {
            if (debug) console.log(`[STORE] getTeams() => ${JSON.stringify(state.teams)}`);
            return state.teams;
        },
        getTeam: (state) => ({ id }) => {
            if (debug) console.log(`[STORE] getTeam(${id})`);
            return state.teams.find(e => e.id === id);
        },
        getMemberships: (state) => () => {
            if (debug) console.log(`[STORE] getMemberships() => ${state.memberships}`);
            return state.memberships;
        },
        getTeamInvites: (state) => () => {
            if (debug) console.log(`[STORE] getTeamInvites() => ${state.teamInvites}`);
            return state.teamInvites;
        },
        getOrganizations: (state) => () => {
            if (debug) console.log(`[STORE] getOrganizations() => ${state.organizations}`);
            return state.organizations;
        },
        getOrganization: (state) => ({ id }) => {
            if (debug) console.log(`[STORE] getOrganization(${id})`);
            return state.organizations.find(e => e.id === id);
        },
        getOrganizationInvites: (state) => () => {
            if (debug) console.log(`[STORE] getOrganizationInvites() => ${state.organizationsInvites}`);
            return state.organizationsInvites;
        },
        getServers: (state) => () => {
            if (debug) console.log(`[STORE] getServers() => ${state.servers}`);
            return state.servers;
        },
        getModals: (state) => () => {
            if (debug) console.log(`[STORE] getModals() => <omitted>`);
            return state.modals;
        },
        getEntryPath: (state) => () => {
            if (debug) console.log(`[STORE] getEntryPath() => ${state.entryPath}`);
            return state.entryPath;
        },
        getServer: (state) => (server_id) => {
            let server = null;
            state.servers.forEach((s) => {
                if (s.id === server_id) {
                    server = s;
                    return;
                }
            })
            if (debug) console.log(`[STORE] getServer(${server_id}) => ${JSON.stringify(server)}`);
            return server;
        }
    },
    actions: {
        updateData({ commit }, data) {
            commit('setSettings', data.ui);

            commit('setAccountName', data.persona.username);

            commit('setPersonaName', data.persona.profile.display_name);
            commit('setPersonaAvatar', data.persona.profile.avatar);

            commit('setUILanguage', data.preferences.language);
            commit('setDTLocale', data.preferences.app.datetime_locale || data.preferences.language);
            commit('setPreferences', data.preferences.app);

            commit('setSubscriberStatus', data.subscription);
            commit('setCapabilities', data.capabilities);
            commit('setSubscriberDetails', data.subscriptions.omega);

            commit('clearIterableResources');

            data.memberships.forEach(element => {
                const team = element.team;
                if (team) {
                    store.commit('addTeam', team);
                    team.role = element.role;
                    store.commit('addMembership', team);
                } else if(element.organization) {
                    store.commit('addOrganization', element.organization)
                }
            });

            for (const team_uuid in data.resources) {
                const resources = data.resources[team_uuid];
                resources.forEach(resource => {
                    if (resource.type === 'server') {
                        store.commit('addServer', resource)
                    }
                });
            }

            data.invites.forEach(element => {
                if(element.team) {
                    store.commit('addTeamInvite', element)
                } else if(element.organization) {
                    store.commit('addOrganizationInvite', element)
                }
            });
        },
        async fetchTeams({ commit }) {
            const response = await fetch(process.env.VUE_APP_ROOT_API + 'v1/teams/list',{ credentials: 'include' });
            if (!response.ok) {
                throw new Error(`(fetchTeams) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
            }
            const data = await response.json()
            commit('setTeams', data.teams)
        },
        async fetchOrganizations({ commit }) {
            const response = await fetch(process.env.VUE_APP_ROOT_API + 'v1/organizations',{ credentials: 'include' });
            if (!response.ok) {
                throw new Error(`(fetchOrganizations) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
            }
            const data = await response.json()
            commit('setOrganizations', data.organizations)
        }
    },
    mutations: {
        update(state, action) {
          action(state)
        },
        setRoute(state, route) {
            if (debug) console.log(`[STORE] setRoute(${JSON.stringify(route)})`);
            state.route = route;
        },
        setSettings(state, settings) {
            if (debug) console.log(`[STORE] setSettings(${JSON.stringify(settings)})`);
            state.settings = settings;
        },
        setSettingsChild(state, update) {
            if (debug) console.log(`[STORE] setSettingsChild(${update.child}, ${update.values})`);
            state.settings[update.child] = update.values;
        },
        actionLogout(state) {
            if (debug) console.log(`[STORE] actionLogout()`);
            state.loginState = SessionLoginState.NOT_LOGGED_IN;
            state.accountId = null;
            state.accountName = null;
        },
        setSessionExpiration(state, expiration) {
            if (debug) console.log(`[STORE] setSessionExpiration(${expiration})`);
            state.expiration = expiration;
        },
        setLoginState(state, loginState) {
            if (debug) console.log(`[STORE] setLoginState(${loginState})`);
            state.loginState = loginState;
        },
        setAccountName(state, accountName) {
            if (debug) console.log(`[STORE] setAccountName(${accountName})`);
            state.accountName = accountName;
        },
        setAccountId(state, accountId) {
            if (debug) console.log(`[STORE] setAccountId(${accountId})`);
            state.accountId = accountId;
        },
        setPersonaName(state, personaName) {
            if (debug) console.log(`[STORE] setPersonaName(${personaName})`);
            state.personaName = personaName;
        },
        setPersonaAvatar(state, personaAvatar) {
            if (debug) console.log(`[STORE] setPersonaAvatar(${personaAvatar})`);
            state.personaAvatar = personaAvatar;
        },
        setUILanguage(state, uiLanguage) {
            if (debug) console.log(`[STORE] setUILanguage(${uiLanguage})`);
            state.uiLanguage = uiLanguage; // Set for local storage
            i18n.locale = uiLanguage; // Set for i18n
        },
        setDTLocale(state, dtLocale) {
            if (debug) console.log(`[STORE] setDTLocale(${dtLocale})`);
            // DateTime locale can NOT have hyphens, as this will break other localization methods
            if(dtLocale.includes('-')) {
                if (debug) console.log(`[WARNING] dtLocale=${dtLocale} is NOT supported, overriding to base language`);
                dtLocale = dtLocale.split('-')[0];
            }
            state.dtLocale = dtLocale;
        },
        setPreferences(state, preferences) {
            if (debug) console.log(`[STORE] setPreferences(${JSON.stringify(preferences)})`);
            state.preferences = preferences;
        },
        setSubscriberStatus(state, status) {
            if (debug) console.log(`[STORE] setUISubscriberStatus(${status})`);
            state.uiSubscriber = status;
        },
        setCapabilities(state, capabilities) {
            if (debug) console.log(`[STORE] setCapabilities(${JSON.stringify(capabilities)})`);
            state.capabilities = capabilities;
        },
        setSubscriberDetails(state, details) {
            if (debug) console.log(`[STORE] setSubscriberDetails(${JSON.stringify(details)})`);
            state.subscriptionDetails = details;
        },
        setModals(state, modals) {
            if (debug) console.log(`[STORE] setModals(${JSON.stringify(modals)})`);
            state.modals = modals;
        },
        setEntryPath(state, path) {
            if (debug) console.log(`[STORE] setEntryPath(${path})`);
            state.entryPath = path;
        },
        clearIterableResources(state) {
            if (debug) console.log(`[STORE] clearIterableResources()`);
            state.roles = [];
            state.servers = [];

            state.memberships = [];

            state.teams = [];
            state.teamInvites = [];

            state.organizations = [];
            state.organizationsInvites = [];

            state.resources = {};
        },
        addServer(state, server) {
            if (debug) console.log(`[STORE] addServer(${JSON.stringify(server)})`);
            state.servers.push(server);
            if (server.team_id) {
                state.resources[server.team_id].push(server);
            }
        },
        removeServer(state, server) {
            if (debug) console.log(`[STORE] removeServer(${JSON.stringify(server)})`);
            let index = state.servers.indexOf(server);
            if (index >= 0) {
                state.servers.splice(index, 1);
            }
            if (server.team_id) {
                let index = state.resources[server.team_id].indexOf(server);
                if (index >= 0) {
                    state.resources[server.team_id].splice(index, 1);
                }
            }
        },
        setServer(state, { server_id, data }) {
            let serverData = null;
            let serverIndex = null;
            state.servers.forEach((s, i) => {
                if (s.id === server_id) {
                    serverData = s;
                    serverIndex = i;
                    return;
                }
            })
            if (debug) console.log(`[STORE] setServer(${server_id}) : ${serverData} > ${data}`);
            if (serverIndex === null) {
                throw new Error(`${server_id} not in store`);
            }
            //state.servers[serverIndex] = null; // TODO: This may lead to performance issues, but force reloads data
            state.servers[serverIndex] = {
                ...serverData,
                ...data
            };
        },
        setMemberships(state, memberships) {
            if (debug) console.log(`[STORE] setMembership(${JSON.stringify(memberships)})`);
            state.memberships = memberships
        },
        addMembership(state, membership) {
            if (debug) console.log(`[STORE] addMembership(${JSON.stringify(membership)})`);
            state.memberships.push(membership);
        },
        removeMembership(state, membership) {
            if (debug) console.log(`[STORE] removeMembership(${JSON.stringify(membership)})`);
            const index = state.memberships.indexOf(membership);
            if (index >= 0) {
                state.memberships.splice(index, 1);
            }
        },
        setTeams(state, teams) {
            if (debug) console.log(`[STORE] setTeams(${JSON.stringify(teams)})`);
            const current = state.teams;
            if(current.length === teams.length) {
                for(let i = 0; i < teams.length; i++) {
                    if(teams[i].id !== current[i].id) {
                        break;
                    }
                    teams[i] = Object.assign(teams[i], current[i]);
                }
            }
            state.teams = teams;
            state.resources = {}
        },
        addTeam(state, team) {
            if (debug) console.log(`[STORE] addTeam(${JSON.stringify(team)})`);
            state.teams.push(team);
            state.resources[team.id] = [];
        },
        updateTeam(state, team) {
            if (debug) console.log(`[STORE] updateTeam(${JSON.stringify(team)})`);
            let value;
            const index = state.teams.findIndex(e => e.id === team.id && (value = e));
            if (index >= 0) {
                state.teams.splice(index, 1, Object.assign({}, value, team));
            }
        },
        removeTeam(state, team) {
            if (debug) console.log(`[STORE] removeTeam(${JSON.stringify(team)})`);
            let index = state.teams.findIndex(e => e.id === team.id);
            if (index >= 0) {
                state.teams.splice(index, 1);
            }
            delete state.resources[team.id];
        },
        addTeamInvite(state, invite) {
            if (debug) console.log(`[STORE] addTeamInvite(${JSON.stringify(invite)})`);
            state.teamInvites.push(invite);
        },
        removeTeamInvite(state, invite) {
            if (debug) console.log(`[STORE] removeTeamInvite(${JSON.stringify(invite)})`);
            const index = state.teamInvites.indexOf(invite);
            if (index >= 0) {
                state.teamInvites.splice(index, 1);
            }
        },
        setOrganizations(state, organizations) {
            if (debug) console.log(`[STORE] setOrganizations(${JSON.stringify(organizations)})`);
            const current = state.organizations;
            if(current.length === organizations.length) {
                for(let i = 0; i < organizations.length; i++) {
                    if(organizations[i].id !== current[i].id) {
                        break;
                    }
                    organizations[i] = Object.assign(current[i], organizations[i]);
                }
            }
            state.organizations = organizations;
        },
        addOrganization(state, organization) {
            if (debug) console.log(`[STORE] addOrganization(${JSON.stringify(organization)})`);
            state.organizations.push(organization);
        },
        updateOrganization(state, organization) {
            if (debug) console.log(`[STORE] updateOrganization(${JSON.stringify(organization)})`);
            const index = state.organizations.findIndex(e => e.id === organization.id);
            if (index >= 0) {
                state.organizations.splice(index, 1, organization);
            }
        },
        removeOrganization(state, organization) {
            if (debug) console.log(`[STORE] removeOrganization(${JSON.stringify(organization)})`);
            const index = state.organizations.findIndex(e => e.id === organization.id);
            if (index >= 0) {
                state.organizations.splice(index, 1);
            }
        },
        addOrganizationInvite(state, invite) {
            if (debug) console.log(`[STORE] addOrganizationInvite(${JSON.stringify(invite)})`);
            state.organizationsInvites.push(invite);
        },
        removeOrganizationInvite(state, invite) {
            if (debug) console.log(`[STORE] removeOrganizationInvite(${JSON.stringify(invite)})`);
            const index = state.organizationsInvites.indexOf(invite);
            if (index >= 0) {
                state.organizationsInvites.splice(index, 1);
            }
        }
    }
})

export default store

