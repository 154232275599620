<style>
.c-desync {
  height: 2rem;
  display: block;
  width: 100%;
  white-space: nowrap;
  padding: 0.4rem 1.5rem;
  position: relative;
  font-size: 13.5px;
  -webkit-transition: all .1s;
  transition: all .1s;
  cursor: pointer;
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
  -o-user-select: none;
  user-select: none;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: dpulse 2s infinite;
}

@keyframes dpulse {
  0% {
    box-shadow: 0 0 0 0 #f46a6a;
  }

  100% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
}

#sidebar-menu .static {
  display: block;
  padding: 0.625rem 1.5rem;
  color: #a6b0cf;
  position: relative;
  font-size: 13px;
  cursor: pointer;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li > span {
  padding-left: 27px;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li > span > span {
  display: none;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > span {
  position: relative;
  width: calc(190px + 70px);
  display: block;
  background: #2e3548;
  color: #ffffff;
}

.reverse-collapsing {
  position: unset !important;
  height: unset !important;
  overflow: unset !important;
  -webkit-transition: unset !important;
  transition: unset !important;
}

#sidebar-menu .visible {
  display: block;
  width: 100%;
}

.no-transition {
  -webkit-transition: unset !important;
  transition: unset !important;
}

#sidebar-menu {
  -webkit-transition: unset !important;
  transition: unset !important;
}

.small-icon {
  font-size: 16px !important;
  min-width: 1.20rem !important;
}

.disable-text-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome, Edge, Opera and Firefox */
}
</style>

<script>
import MetisMenu from "metismenujs/dist/metismenujs";

import { layoutMethods } from "@/state/helpers";

import { menuItems, accountMenu } from "./menu";
import { mapGetters } from "vuex";

import eventHandler from "@/state/events";

export default {
  components: {
    ServerNav: () => import('@/components/widgets/ServerNav')
  },
  data() {
    return {
      desynchronized: !(this.$socket.connected),
      menuItems: menuItems,
      accountMenu: accountMenu,
      timeout: null
    };
  },
  computed: {
    ...mapGetters({
      getSubscriberStatus: 'getSubscriberStatus',
      getServers: 'getServers',
      getAccountId: 'getAccountId',
      getSettings: 'getSettings'
    }),
  },
  created() {
    if (this.$socket.$subscribe) {
      this.$socket.client.on('ui:reload:components', this.handleUiReload);
    }
    eventHandler.$on('reload-navigation', this.handleUiReload);
    this.bootstrapUI();
  },
  mounted: function () {
    this.handleMounted();
  },
  destroyed() {
    this.$socket.client.off('ui:reload:components', this.handleUiReload);
    eventHandler.$off('reload-navigation', this.handleUiReload);
  },
  sockets: {
    connect() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.desynchronized = false;
    },
    disconnect() {
      this.timeout = setTimeout(() => {
        this.desynchronized = true;
      }, 1000 * 60);
    },
    beforeDestroy() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
    },
  },
  methods: {
    ...layoutMethods,
    change_layout(layout) {
      return this.changeLayoutType({ layoutType: layout });
    },
    bootstrapUI() {
      let one = 1;
      if (one === 1) {
        let menuItems = [];
        this.menuItems.forEach((item) => {
          if (item.name && item.name === 'account_menu') {
            if (this.getSettings().menu_layout !== 'legacy') {
              return;
            }
          }
          if (!item.requiresSubscription || this.getSubscriberStatus() || item.validate?.() || this.getServers().length) {
            if (item._badge) {
              item.badge = item._badge()
            }
            if (item.subItems) {
              let subItems = [];
              item.subItems.forEach((subItem) => {
                if (!subItem.requiresSubscription || this.getSubscriberStatus() || this.getServers().length) {
                  subItems.push(subItem);
                }
              });
              item.subItems = subItems;
            }
            menuItems.push(item);
          }
        });
        this.menuItems = [];
        this.menuItems = menuItems;
      }
    },
    handleMounted() {
      document.body.setAttribute("data-sidebar", "dark");
      // eslint-disable-next-line no-unused-vars
      var menuRef = new MetisMenu("#side-menu");
      var links = document.getElementsByClassName("side-nav-link-ref");

      let matchingMenuItems = [];
      for (var i = 0; i < links.length; i++) {
        if (window.location.pathname === links[i].pathname || (
            window.location.pathname.startsWith(links[i].pathname) && (
                window.location.pathname.startsWith(`/profile/${this.getAccountId()}`)
                ||
                !window.location.pathname.startsWith('/profile/')
            )
        )) {
          let matchingMenuItem = links[i];
          matchingMenuItems.push(matchingMenuItem);
        }
      }
      matchingMenuItems.forEach(matchingMenuItem => {
        matchingMenuItem.classList.add("active");
        var parent = matchingMenuItem.parentElement;

        if (parent) {
          parent.classList.add("mm-active");
          const parent2 = parent.parentElement.closest("ul");
          if (parent2 && parent2.id !== "side-menu") {
            parent2.classList.add("mm-show");

            const parent3 = parent2.parentElement;
            if (parent3) {
              parent3.classList.add("mm-active");
              var childAnchor = parent3.querySelector(".has-arrow");
              var childDropdown = parent3.querySelector(".has-dropdown");
              if (childAnchor) {
                childAnchor.classList.add("mm-active");
              }
              if (childDropdown) {
                childDropdown.classList.add("mm-active");
              }

              const parent4 = parent3.parentElement;
              if (parent4 && parent4.id !== "side-menu") {
                parent4.classList.add("mm-show");
                const parent5 = parent4.parentElement;
                if (parent5 && parent5.id !== "side-menu") {
                  parent5.classList.add("mm-active");
                  const childanchor = parent5.querySelector(".is-parent");
                  if (childanchor && parent5.id !== "side-menu") {
                    childanchor.classList.add("mm-active");
                  }
                }
              }
            }
          }
        }
      });
    },
    async handleUiReload() {
      setTimeout(() => {
        this.menuItems = menuItems;
        this.accountMenu = accountMenu;
        this.$nextTick(() => {
          this.bootstrapUI();
          this.handleMounted();
        });
      }, 100);
    },
    lightSidebar() {
      document.body.setAttribute("data-topbar", "dark");
      document.body.removeAttribute("data-sidebar");
      document.body.removeAttribute("data-layout-size", "boxed");
      document.body.removeAttribute("data-sidebar-size", "small");
      document.body.classList.remove("vertical-collpsed");
    },
    compactSidebar() {
      document.body.setAttribute("data-sidebar-size", "small");
      document.body.setAttribute("data-sidebar", "dark");
      document.body.removeAttribute("data-layout-size", "boxed");
      document.body.classList.remove("vertical-collpsed");
      document.body.removeAttribute("data-topbar", "dark");
    },
    iconSidebar() {
      document.body.setAttribute("data-keep-enlarged", "true");
      document.body.classList.add("vertical-collpsed");
      document.body.setAttribute("data-sidebar", "dark");
      document.body.removeAttribute("data-topbar", "dark");
      document.body.removeAttribute("data-layout-size", "boxed");
    },
    boxedLayout() {
      document.body.setAttribute("data-keep-enlarged", "true");
      document.body.classList.add("vertical-collpsed");
      document.body.setAttribute("data-layout-size", "boxed");
      document.body.removeAttribute("data-sidebar", "colored");
      document.body.setAttribute("data-sidebar", "dark");
      document.body.removeAttribute("data-topbar", "dark");
    },
    coloredSidebar() {
      document.body.setAttribute("data-sidebar", "colored");
      document.body.removeAttribute("data-layout-size", "boxed");
      document.body.removeAttribute("data-sidebar-size", "small");
      document.body.classList.remove("vertical-collpsed");
    },
    /**
     * Returns true or false if given menu item has child or not
     * @param item menuItem
     */
    hasItems(item) {
      return item.subItems !== undefined ? item.subItems.length > 0 : false;
    },
    isActive(path) {
      return this.$route.path.startsWith(path);
    },
    subIsActive(path) {
      return this.$route.path === path;
    },
    /* Custom */
    async showServerNavConfiguration() {
      alert('clicked');
    }
  }
};
</script>

<template>
  <!-- ========== Left Sidebar Start ========== -->
  <!--- Sidemenu -->
  <div id="sidebar-menu" class="disable-text-select">
    <div class="c-desync bg-danger text-white" v-if="desynchronized">
      <i class="fas fa-exclamation-triangle"/>
      {{ $t('generic.desynchronized') }}
    </div>

    <!-- Left Menu Start -->
    <ul id="side-menu" class="metismenu list-unstyled">
      <template v-if="getSettings().menu_layout !== 'legacy'">
        <li class="mm-active no-transition">

			    <span
              class="is-parent static"
          >
				    <i><img src="/logo.svg" width="21" style="margin-right: 0.45rem; margin-top: -0.15rem;"></i>
				    <span>{{ $t(accountMenu.label) }}</span>
			    </span>

          <ul class="sub-menu reverse-collapsing no-transition mm-show" aria-expanded="true">
            <li v-for="(subitem, index) of accountMenu.subItems" :key="index" class="no-transition">
              <router-link
                  :to="subitem.link"
                  v-if="!hasItems(subitem)"
                  class="side-nav-link-ref no-transition"
              >
                <i class="small-icon" :class="subitem.icon" v-if="subitem.icon"/>
                {{ $t(subitem.label) }}
              </router-link>
              <a
                  v-if="hasItems(subitem)"
                  class="side-nav-link-a-ref has-arrow no-transition"
                  href="javascript:void(0);"
              >
                <i class="small-icon" :class="subitem.icon" v-if="subitem.icon"/>
                {{ $t(subitem.label) }}
              </a>
              <ul v-if="hasItems(subitem)" class="sub-menu mm-collapse no-transition" aria-expanded="false">
                <li v-for="(subSubitem, index) of subitem.subItems" :key="index">
                  <router-link
                      :to="subSubitem.link"
                      class="side-nav-link-ref no-transition"
                  >
                    <i class="small-icon" :class="subitem.icon" v-if="subitem.icon"/>
                    {{ $t(subSubitem.label) }}
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <hr class="p-0 m-1"/>
      </template>
      <template v-for="item in menuItems">
        <li class="menu-title w-100" v-if="item.isTitle" :key="item.id">
          <div class="d-inline-block w-50">
            {{ $t(item.label) }}
          </div>
          <!--
          <template v-if="getSettings().servernav === 'experimental'">
            <button class="btn btn-primary d-inline" v-on:click="showServerNavConfiguration()" style="z-index: 9999999;">
              <i class=" fad fa-cog text-white align-middle" style="font-size: 20px;"  />
            </button>
          </template>

          <router-link to="/servers/add" v-if="item.serversAddButton" class="d-inline" style="pointer-events: auto !important;">
            <i class="fad fa-plus text-info float-right align-middle" style="font-size: 20px;"></i>
          </router-link>
          -->
          <div class="d-inline-block w-50 align-middle">
            <div class="d-inline-block float-right ml-1 mr-0">
              <router-link to="/servers/add" class="p-0 m-0 float-right" v-if="item.serversAddButton"
                           style="pointer-events: auto !important; position: unset !important; display: unset !important;">
                <i class="fad fa-plus text-info" style="font-size: 20px;"></i>
              </router-link>
            </div>
            <template v-if="getSettings().servernav !== 'legacy' && getServers().length">
              <div class="d-inline-block float-right ml-1 mr-1">
                <router-link to="/preferences/server-nav" class="p-0 m-0 float-right" v-if="item.serversAddButton"
                             style="pointer-events: auto !important; position: unset !important; display: unset !important;">
                  <i class=" fad fa-cog text-white align-middle" style="font-size: 20px;"/>
                </router-link>
              </div>
            </template>
          </div>
        </li>
        <!-- Layouts menu -->
        <li v-else :key="item.id">
          <a
              v-if="hasItems(item)"
              href="javascript:void(0);"
              class="is-parent"
              :class="{'has-arrow': !item.badge, 'has-dropdown': item.badge}"
          >
            <template v-if="item.icon.startsWith('$brand')">
              <img src="/logo.svg" width="21" style="margin-right: 0.45rem; margin-top: -0.15rem;">
            </template>
            <template v-else>
              <i :class="`${item.icon}`" v-if="item.icon"></i>
            </template>
            <span>{{ $t(item.label) }}</span>
            <span
                :class="`badge badge-pill badge-${item.badge.variant} float-right`"
                v-if="item.badge">{{ $t(item.badge.text) }}</span>
          </a>

          <router-link :to="item.link" v-if="!hasItems(item)" class="side-nav-link-ref">
            <template v-if="item.icon.startsWith('$brand')">
              <img src="/logo.svg" width="21" style="margin-right: 0.45rem; margin-top: -0.15rem;">
            </template>
            <template v-else>
              <i :class="`${item.icon}`" v-if="item.icon"></i>
            </template>
            <span>{{ $t(item.label) }}</span>
            <span
                :class="`badge badge-${item.badge.variant} float-right`"
                v-if="item.badge"
            >{{ $t(item.badge.text) }}</span>
          </router-link>

          <ul v-if="hasItems(item)" class="sub-menu" aria-expanded="false">
            <li v-for="(subitem, index) of item.subItems" :key="index">
              <router-link
                  :to="subitem.link"
                  v-if="!hasItems(subitem)"
                  class="side-nav-link-ref"
              >{{ $t(subitem.label) }}
              </router-link>
              <a
                  v-if="hasItems(subitem)"
                  class="side-nav-link-a-ref has-arrow"
                  href="javascript:void(0);"
              >{{ $t(subitem.label) }}</a>
              <ul v-if="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">
                <li v-for="(subSubitem, index) of subitem.subItems" :key="index">
                  <router-link
                      :to="subSubitem.link"
                      class="side-nav-link-ref"
                  >{{ $t(subSubitem.label) }}
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </template>
    </ul>
    <template v-if="getSettings().servernav === 'legacy'">
      <ServerWidget/>
    </template>
    <template v-else>
      <ServerNav/>
    </template>
  </div>
  <!-- Sidebar -->
</template>
