<script>
import { layoutComputed } from "@/state/helpers";
import Vertical from "@/router/layouts/vertical";
import {get_acsrf_token} from "@/methods";

export default {
  components: { Vertical },
  data() {
    return {};
  },
  computed: {
    ...layoutComputed
  },
  methods: {

  }
};
</script>

<template>
  <div>
    <Vertical :layout="layoutType">
      <slot />
    </Vertical>
  </div>
</template>
