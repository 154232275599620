<style>
  .c-server-container {
    max-width: 250px !important;
    overflow: hidden;
    -webkit-user-select: none; /* Chrome/Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+ */
    -o-user-select: none;
    user-select: none;
  }
  .c-server {
    height: 2rem;
    display: block;
    width: 100%;
    white-space: nowrap;
    padding: 0.4rem 1.5rem;
    position: relative;
    font-size: 13.5px;
    -webkit-transition: all .1s;
    transition: all .1s;
    cursor: pointer;
  }
  .c-server.expanded {
    background: #31394f;
    /*height: 6.5rem;*/
    height: 9rem;
  }
  .c-server.expanded .c-server-name {
    color: white;
  }
  .c-server-slots {
    font-size: 14px;
  }
  .c-server:hover {
    background: #31394f;
    color: white;
  }
  .c-server-icon img {
    margin-right: 0.5rem;
    height: 16px;
    width: 16px;
  }
  .c-server-name {
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    overflow: hidden !important;
  }
  .vertical-collpsed .vertical-menu .c-server-container .c-server-name {
    display: none !important;
  }
  .vertical-collpsed .vertical-menu .c-server-container .c-server-icon img {
    margin-left: 0.1rem;
    margin-right: 1rem;
    height: 125%;
    width: auto;
  }
  .c-server-details {
    display: none;
  }
  .c-server.expanded .c-server-details {
    display: block;
    -webkit-transition: all .4s;
    transition: all .4s;
  }
  .c-server-desync {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    transform: scale(1);
    animation: pulse 2s infinite;
  }
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 #f46a6a;
    }

    100% {
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }
  }
  .btn-outline-dark:hover {
    color: black !important;
  }
  .hard-border {
    border-radius: 0 !important;
  }
  .btn-new-pulse {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    transform: scale(1);
    animation: pulse 2s infinite;
  }
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 #4285F4;
    }

    100% {
      box-shadow: 0 0 0 0.5rem rgba(0, 0, 0, 0);
    }
  }
</style>

<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config.json";
import {mapGetters, mapState} from "vuex";
import {WorkerState} from '@/enums';
import store from "@/state/store";

const containsKey = (obj, key ) => Object.keys(obj).includes(key);
export default {
  computed: {
    ...mapGetters({
      getServers: 'getServers',
      getServer: 'getServer'
    }),
    iterableServers() {
      let servers = [];
      Object.keys(this.servers).forEach((server_id) => {
        servers.push(this.servers[server_id]);
      });
      return servers;
    }
  },
  methods: {
    onServerRouteLeft(server_id) {
      if(server_id) this.hideServer(server_id);
    },
    onServerRouteEntered(server_id) {
      if(server_id) this.serverClicked(server_id);
    },
    serverClicked(server_id) {
      if(this.expandedServerId) this.hideServer(this.expandedServerId);
      this.expandedServerId = server_id;
      this.expandServer(server_id);
    },
    hideServer(server_id) {
      if(this.$refs[server_id].length === 0) return;
      this.$refs[server_id][0].classList.remove('expanded');
    },
    expandServer(server_id) {
      if(this.$refs[server_id].length === 0) return;
      this.$refs[server_id][0].classList.add('expanded');
      this.$refs[server_id][0].scrollIntoView({ block: 'end', behavior: 'smooth' });
    },
    async getData() {
      this.desynchronized = false;
      this.servers = this.getServers();

      /*
      let server_ids = []
      this.getServers().forEach((server) => {
        console.log(server);
      });
      */


      /*
      try {
        let url = new URL(process.env.VUE_APP_ROOT_API + `v1/servers/states`);
        url.searchParams.append('server_ids', JSON.stringify(server_ids));

        let response = await fetch(url, {
          method: 'GET',
          credentials: 'include'
        });

        if(response.ok) {
          let data = await response.json();
          this.servers = [];
          data.servers.forEach((server) => {
            this.servers.push(server);
          });
        } else {
          if (response.status === 429) this.$toast.warning(this.$t('error.server.ratelimit.title'));
          throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }

      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.desynchronized = true;
      }
      */
    },
    routeCheck() {
      if(this.$route.params.server_id) {
        if(this.activeServerId) {
          if(this.activeServerId !== this.$route.params.server_id) {
            this.onServerRouteLeft(this.activeServerId);
            this.activeServerId = this.$route.params.server_id;
            this.onServerRouteEntered(this.activeServerId);
          }
        } else {
          this.activeServerId = this.$route.params.server_id;
          this.onServerRouteEntered(this.activeServerId);
        }

      } else {
        if(this.activeServerId) {
          this.activeServerId = null;
          this.onServerRouteLeft(this.activeServerId);
        }
      }
    },
    secureRouteCheck() {
      setTimeout(() => {
        try {
          this.routeCheck();
        } catch {
          this.secureRouteCheck();
        }
      }, 100); // Execute directly after rendering
    },
    triggerServerUpdate() {
      this.servers = [];
      this.getData();
    },
    async onServerDestroyed(event) {
      let server_id = event['server_id'];
      let server = this.getServer(server_id);
      if(process.env.NODE_ENV === 'development') console.log(`[WS] Server ${server_id} was deleted`);
      if(this.activeServerId === server_id) {
        await this.$swal({
          icon: 'error',
          text: `The server you are currently viewing got deleted. You will be redirected...`,
          title: `${server.identifier} got deleted`,
        });
        await this.$router.push({name: 'dashboard'});
      }
    }
  },
  created() {
    this.getData();
    if(this.$socket.$subscribe) {
      //this.desynchronized = false;
      this.$socket.client.on('update', this.triggerServerUpdate);

      this.$socket.client.on('server:destroy', this.onServerDestroyed);

      this.$socket.client.on('update:server', this.triggerServerUpdate); // Update for server (user)
      this.$socket.client.on('server:update', this.triggerServerUpdate); // Update from server (Omega)
      this.$socket.client.on('server:state', this.triggerServerUpdate); // State change (Omega)

      this.$socket.client.on('gsm:player:create', this.triggerServerUpdate); // player join
      this.$socket.client.on('gsm:player:destruct', this.triggerServerUpdate); // player leave
    } else {
      //this.desynchronized = true;
    }
  },
  mounted() {
    this.secureRouteCheck();
  },
  destroyed() {
    this.$socket.client.off('update', this.triggerServerUpdate);

    this.$socket.client.off('server:destroy', this.onServerDestroyed);

    this.$socket.client.off('update:server', this.triggerServerUpdate); // Update for server (user)
    this.$socket.client.off('server:update', this.triggerServerUpdate); // Update from server (Omega)
    this.$socket.client.off('server:state', this.triggerServerUpdate); // State change (Omega)

    this.$socket.client.off('gsm:player:create', this.triggerServerUpdate); // player join
    this.$socket.client.off('gsm:player:destruct', this.triggerServerUpdate); // player leave
  },
  data() {
    return {
      WorkerState: WorkerState,
      activeServerId: null,
      expandedServerId: null,
      desynchronized: false,
      routeWatcher: null,
      servers: []
    }
  },
  watch: {
    '$store.state.servers': function() {
      console.log('[DEBUG] store servers update');
    }
  }
};
</script>

<template>
  <div class="c-server-container align-middle" v-if="servers.length">
    <div class="c-server c-server-desync bg-danger text-white" v-if="desynchronized">
      <i class="fas fa-exclamation-triangle" />
      {{$t('servers.desynchronized')}}
    </div>
    <div :ref="server.id" class="c-server" v-for="server in servers" :key="server.id" v-on:click="serverClicked(server.id)">
      <div class="row">
        <div class="col-10 c-server-name">
          <div class="c-server-icon d-inline">
            <img :src="'/' +gameIcon(server.game)" alt="server game logo">
          </div>
          <div class="c-server-name d-inline">
            {{ server.identifier }}
          </div>
        </div>
        <div class="col-2">
          <div class="c-server-slots d-inline float-right">
            <span class="badge badge-warning text-black" v-if="server.online === false && (server.state === WorkerState.ERROR || server.state === WorkerState.DISCONNECTED)">
              <i class="far fa-exclamation-triangle" /> Offline
            </span>
            <span class="badge badge-danger" v-else-if="server.error && server.state === WorkerState.ERROR">
              <i class="far fa-exclamation-triangle" /> Error
            </span>
            <span class="badge badge-warning" v-else-if="server.warning && (server.state === WorkerState.ERROR || server.state === WorkerState.DISCONNECTED)">
              <i class="far fa-exclamation-triangle" /> Warning
            </span>
            <span class="badge badge-warning text-black" v-else-if="server.state === WorkerState.DISCONNECTED">
              <i class="far fa-exclamation-triangle" /> Not connected
            </span>
            <span class="badge badge-info text-black" v-else-if="server.state === WorkerState.STARTING">
              <i class="fas fa-spinner fa-spin" />
            </span>
            <span class="badge badge-primary" v-else-if="server.status">
              <b>{{ server.status.players }}</b> / <b>{{ server.status.slots }}</b>
              <template v-if="server.status.queue.active">
                <b>+ {{server.status.queue.size}} </b>
              </template>
            </span>
          </div>
        </div>
      </div>
      <div class="c-server-details mt-2">
        <div class="row justify-content-center">
          <div class="col-10">
            <router-link :to="`/dashboard/${server.id}`" >
              <button class="btn btn-outline-primary btn-block btn-sm">
                Dashboard
              </button>
            </router-link>
          </div>
        </div>
        <div class="row mt-2 justify-content-center">
          <div class="col-10">
            <router-link :to="`/server/${server.id}/overview`">
              <button class="btn btn-outline-dark btn-block btn-sm">
                Manage
              </button>
            </router-link>
          </div>
        </div>
        <div class="row mt-2 justify-content-center">
          <div class="col-10">
            <router-link :to="`/server/${server.id}/logs-server`">
              <button class="btn btn-outline-info btn-block btn-sm">
                Logs
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="w-100" v-else>
    <router-link to="/servers/add" style="pointer-events: auto !important;">
      <button class="btn hard-border btn-new-pulse btn-primary btn-block">
        <i class="fad fa-plus"/>
        Add server
      </button>
    </router-link>
  </div>
</template>
