<style>
.c-news-modal .subtitle {
  display: flex;
  align-items: center;
  font-weight: 500;
  text-transform: uppercase;
}
.c-news-modal .subtitle::after {
  content: "";
  flex-grow: 1;
  height: 2px;
  margin-left: 20px;
  background-color: white;
}
.c-news-modal ul {
  list-style-type: none;
}
.c-news-modal ul li {
  margin-top: 1rem;
}
.c-news-modal ul li:before {
  content: "";
  width: 10px;
  height: 10px;
  float: left;
  border-radius: 50%;
  margin: .5em 0 0 -30px;
  background-color: var(--primary);
}
.c-news-modal .c-danger li:before {
  background-color: var(--danger);
}
.c-news-modal h4 {
  text-transform: uppercase !important;
  margin-bottom: 5px;
}
.c-news-modal span.feature-content {
  font-size: 16px;
  color: white;
}
.c-update-modal-container {
  background-color: transparent;
}
.c-update-modal {
  position: absolute !important;
  top: 30px !important;
  right: 30px !important;
  left: unset !important;
  bottom: 0 !important;
  z-index: 10040 !important;
  overflow: auto;
  user-select: none;
}
</style>

<script>
import appConfig from "@/app.config";
import { notificationMethods } from "@/state/helpers";
import store from "@/state/store";
import {mapGetters} from "vuex";

import { refreshPageMixin } from '@/components/mixins/refresh-page.js';

export default {
  name: "app",
  mixins: [refreshPageMixin],
  computed: {
    ...mapGetters([
      'getSubscriberDetails'
    ])
  },
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} • ${appConfig.title}` : appConfig.title;
    }
  },
  watch: {
    /**
     * Clear the alert message on route change
     */
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      // clear alert on location change
      this.clearNotification();
      if(this.getSubscriberDetails().restricted) {
        if(localStorage.subscriptionRestricted) {
          if(new Date().getTime() - parseInt(localStorage.subscriptionRestricted) >= 1000 * 60 * 60 * 4) {
            this.$refs['subscriptionRestricted'].show();
          }
        } else this.$refs['subscriptionRestricted'].show();
      } else {
        if (from && from.name === 'upgrade' && this.campaignToShow) {
          this.$refs[this.campaignToShow].show();
        }
      }
    }
  },
  methods: {
    clearNotification: notificationMethods.clear,
    async loadCampaigns() {
      try {
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/campaigns`, {
          method: 'GET',
          credentials: 'include'
        });
        if(response.ok) {
          let data = await response.json();
          let campaigns = data.campaigns;

          campaigns.every((campaign) => {
            if(this.$refs[campaign]) {
              this.campaignToShow = campaign;
              return false;
            }
            return true;
          });
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
      }
    },
    async acknowledgeSubscriptionRestricted() {
      this.$refs['subscriptionRestricted'].hide();
      let ts = new Date().getTime();
      localStorage.subscriptionRestricted = ts;
    }
  },
  async created() {
    await this.loadCampaigns();
  },
  mounted() {
    if(this.$applicationEnvironment === 'production') {
      this.initVersionCheck(1000 * 60 * 1);
    }
  },
  data() {
    return {
      campaignToShow: null
    }
  }
};
</script>

<template>
  <div id="app">
    <router-view :key="$route.fullPath"></router-view>

    <div class="modal-open d-block" ref="appUpdateModal" v-if="hashChanged && $applicationEnvironment === 'production'">
      <div class="c-update-modal">
        <div class="modal-content modal-sm bg-primary">
          <div class="modal-header c-update-pulse">
            <h5 class="modal-title mt-0 pb-0">
              Update Available
              <div class="small text-muted">
                Refresh page to apply
              </div>
            </h5>
            <span class="ml-3">
              <button type="button" data-dismiss="modal" class="btn btn-light mt-1" @click="reloadApp">
                <i class="fad fa-redo-alt"/> Update
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>


    <!--
    <b-modal
        id="appUpdateModal"
        ref="appUpdateModal"
        size="sm"
        hide-header
        hide-footer
        content-class="hard-border c-update-modal"
        :scrollable="true"
    >
      Updated
    </b-modal>
    -->

    <!-- update_M10Y2021 -->
    <b-modal
        id="update_M10Y2021"
        ref="update_M10Y2021"
        size="lg"
        hide-header
        hide-footer
        content-class="hard-border c-news-modal"
        :scrollable="true"
    >
      <div>
        <div class="news-header">
          <h3 class="d-inline-block">
            Platform Update
            <small class="text-muted text-uppercase">
              October 2021
            </small>
          </h3>
          <button class="btn btn-sm btn-outline-primary float-right d-inline-block" @click="$bvModal.hide('update_M10Y2021')">
            Got it
          </button>
        </div>

        <div class="news-message">
          <span class="feature-content">
            This one took a while. We really have taken our time with this update as in addition to all the noticeable changes, we also have fixed countless bugs and improved the systems performance across the board.
          </span>
        </div>

        <h3 class="text-primary subtitle mt-4">Changes</h3>
        <ul>
          <!-- ArmA 3 support -->
          <li class="feature">
            <h4 class="feature-title">ArmA 3 Game Support</h4>
            <span class="feature-content">
              ArmA 3 is now supported by CFTools Cloud. You can add your ArmA 3 servers via the add server menu.
            </span>
          </li>

          <!-- Dashboard Upgrade -->
          <li class="feature">
            <h4 class="feature-title">Server Dashboard Upgrade</h4>
            <span class="feature-content">
              We gave the server dashboard a new spin and it is now more powerful with features like weather and time control.
            </span>
          </li>

          <!-- Navigation Overhaul -->
          <li class="feature">
            <h4 class="feature-title">Navigation Overhaul</h4>
            <span class="feature-content">
              Navigation CFTools Cloud is now easier then ever with improved user flows and restructuring.
            </span>
          </li>

          <!-- Customization -->
          <li class="feature">
            <h4 class="feature-title">Customization</h4>
            <span class="feature-content">
              We have added the ability for you to re-order your game servers in the navigation bar.
            </span>
          </li>

          <!-- Ban System Changes -->
          <li class="feature">
            <h4 class="feature-title">Ban System Changes</h4>
            <span class="feature-content">
              In order to better adapt to the changing environment of game server administration, we have removed ban list verification as it were and brought in a new system to publicly display bans.

              Ban list owners now no longer have to go through the time intensive process of verification but instead can enable the public display for their bans from the ban list settings if their ban list matches certain criteria.

              We also brought in the ability to report ban list entries in order to fight malicious actors.
            </span>
          </li>
        </ul>

        <!-- Smaller changes -->
        <h4 class="text-primary subtitle mt-4">Other changes</h4>
        <ul>
          <li class="feature">
            <h4 class="feature-title">Credit Card As New Payment Method</h4>
          </li>
          <li class="feature">
            <h4 class="feature-title">Team Based Watchlists</h4>
          </li>
          <li class="feature">
            <h4 class="feature-title">IPv4 Player Search</h4>
          </li>
          <li class="feature">
            <h4 class="feature-title">Settings for the server alert system</h4>
          </li>
        </ul>
      </div>
    </b-modal>
    <!-- update_M10Y2021 END -->

    <!-- rollout_banreporting -->
    <b-modal
        id="rollout_banreporting"
        ref="rollout_banreporting"
        size="lg"
        hide-header
        hide-footer
        content-class="hard-border c-news-modal"
        :scrollable="true"
        :no-close-on-backdrop="true"
    >
      <div>
        <div class="news-header">
          <h3 class="d-inline-block">
            Ban Reporting
            <small class="text-muted text-uppercase">
              July 2022
            </small>
          </h3>
          <button class="btn btn-sm btn-outline-primary float-right d-inline-block" @click="$bvModal.hide('rollout_banreporting')">
            Got it
          </button>
        </div>

        <div class="news-message">
          <span class="feature-content text-center">
            <h3 class="text-success mt-2">
              <i class="fad fa-siren-on"/>
              You are now eligable for ban reporting
              <i class="fad fa-siren-on"/>
            </h3>
          </span>
        </div>

        <h3 class="text-white subtitle mt-4">New</h3>
        <ul>
          <li class="feature">
            <h4 class="feature-title">Report Bans</h4>
            <span class="feature-content">
              Report public ban entries, you feel are malicious.
            </span>
          </li>

          <li class="feature">
            <h4 class="feature-title">Gain Reputation</h4>
            <span class="feature-content">
              Actively reporting malicious ban entries which are verified as malicious makes you gain reputation. Users with high reputation will earn reviewer status.
            </span>
          </li>

          <li class="feature">
            <h4 class="feature-title">Reviewer Badge</h4>
            <span class="feature-content">
              The top reviewers will gain a unique profile badge, showing their engagement status.
            </span>
          </li>
        </ul>

        <!-- Smaller changes -->
        <h4 class="text-primary subtitle mt-4">Other changes</h4>
        <ul>
          <li class="feature">
            <h4 class="feature-title">Automated public listing of eligible ban lists</h4>
          </li>
          <li class="feature">
            <h4 class="feature-title">Reworked ban list verification <small><a href="https://help.cftools.com/cf-tools-cloud/ban-list-basics" target="_blank">More info <i class="fad fa-external-link-square"/></a></small></h4>
          </li>
        </ul>
      </div>
    </b-modal>
    <!-- rollout_banreporting END -->

    <!-- rollout_gamelabs_poll2 -->
    <b-modal
        id="rollout_gamelabs_poll2"
        ref="rollout_gamelabs_poll2"
        size="lg"
        hide-header
        hide-footer
        content-class="hard-border c-news-modal"
        :scrollable="true"
        :no-close-on-backdrop="true"
    >
      <div>
        <div class="news-header">
          <h3 class="d-inline-block">
            Dynamic Actions
            <small class="text-muted text-uppercase">
              August 2022
            </small>
          </h3>
          <button class="btn btn-sm btn-outline-primary float-right d-inline-block" @click="$bvModal.hide('rollout_gamelabs_poll2')">
            Got it
          </button>
        </div>

        <div class="news-message">
          <span class="feature-content text-center">
            <br>
            Today we have shipped GameLabs v1.81, which introduces dynamic actions, which allows mod developers to embed custom actions into their mods which are available for execution from the CFTools Cloud web interface.<br>
            <br>
            With this options, you will not only be able to better interact with dynamic content from the live map, but also manage your server with custom actions of your liking. We have also introduced a new set of functions for spawning items, to enable stacking and spawning with debug options.<br>
            <br>
            To enable Team members to use dynamic actions, a new permission has been introduced "Use GameLabs dynamic actions" which can be found under "Server". Static actions still retain their current permission set, even though they have been implemented as dynamic action.<br>
            <br>
            You may find the documentation for dynamic actions on <a href="https://github.com/CFToolsGameLabs/game-plugin-dayz/tree/master/examples/dynamic_actions" target="_blank">GitHub</a>.
          </span>
        </div>

        <h3 class="text-white subtitle mt-4">New</h3>
        <ul>
          <li class="feature">
            <h4 class="feature-title">Context Menus</h4>
            <span class="feature-content">
              Right click vehicles and players for new actions
            </span>
          </li>

          <li class="feature">
            <h4 class="feature-title">Dynamic Actions</h4>
            <span class="feature-content">
              Create custom actions and make them available on the CFTools Cloud interface
            </span>
          </li>

          <li class="feature">
            <h4 class="feature-title">Deep integration</h4>
            <span class="feature-content">
              Contact your favourite mod authors for a deep integration into CFTools Cloud using dynamic actions
            </span>
          </li>
        </ul>
      </div>
    </b-modal>
    <!-- rollout_gamelabs_poll2 END -->

    <!-- player_intelligence -->
    <b-modal
        id="player_intelligence"
        ref="player_intelligence"
        size="lg"
        hide-header
        hide-footer
        content-class="hard-border c-news-modal"
        :scrollable="true"
        :no-close-on-backdrop="true"
    >
      <div>
        <div class="news-header">
          <h3 class="d-inline-block">
            Player Intelligence Limitations
            <small class="text-muted text-uppercase">
              August 2022
            </small>
          </h3>
          <button class="btn btn-sm btn-outline-primary float-right d-inline-block" @click="$bvModal.hide('player_intelligence')">
            Continue
          </button>
        </div>

        <div class="news-message">
          <span class="feature-content text-center">
            <br>
            Operating a public platform comes with many challenges, primarily handling abuse and offering a level playing field.<br>
            In recent times we have been aware of an ever increasing amount of servers registered with the sole purpose of gaining access to our database while the server was not in use or being administrated.<br>
            Our first step to prevent this behavior, is introducing a restriction on the global player intelligence features.<br>
            <br>
            Some player intelligence features that have been primarily abused are now only available with one of our paid plans or if your Free plan server has legitimate player traffic. This is being dynamically decided by our system.<br>
            While we acknowledge that this will impact some of the legitimate Free plan users, we believe this is the right step in order to make our platform a safer space for administrators.
          </span>
        </div>
      </div>
    </b-modal>
    <!-- update_M10Y2021 END -->

    <b-modal
        id="subscriptionRestricted"
        ref="subscriptionRestricted"
        size="lg"
        hide-header
        hide-footer
        content-class="hard-border c-news-modal"
        :scrollable="true"
        :no-close-on-backdrop="true"
    >
      <div>
        <div class="news-header">
          <h3 class="d-inline-block text-uppercase">
            {{ $t('billing.restricted.title') }}
          </h3>
          <button class="btn btn-sm btn-outline-danger float-right d-inline-block" @click="acknowledgeSubscriptionRestricted()">
            {{ $t('billing.restricted.close') }}
          </button>
        </div>

        <div class="news-message">
          <span class="feature-content">
            {{ $t('billing.restricted.message') }}
          </span>
        </div>

        <h3 class="text-danger subtitle mt-4">{{ $t('billing.restricted.restrictions.title') }}</h3>
        <ul class="c-danger">
          <li class="feature">
            <h4 class="feature-title">{{ $t('billing.restricted.restrictions.resources') }}</h4>
          </li>

          <li class="feature">
            <h4 class="feature-title">{{ $t('billing.restricted.restrictions.billing') }}</h4>
          </li>
        </ul>

        <div class="news-message">
          <span class="feature-content">

          </span>
        </div>

        <div class="row justify-content-center mt-4">
          <div class="col text-center">
            <h5 class="p-0 text-uppercase">
              {{ $t('billing.restricted.actions') }}
            </h5>
          </div>
        </div>

        <div class="row justify-content-center mt-4">
          <div class="col">
            <div class="text-center">
              <router-link :to="{name: 'billing_component', params: {component: 'invoices'}}">
                <button class="btn btn-primary btn-lg text-uppercase" v-on:click="$bvModal.hide('subscriptionRestricted')">
                  <h5 class="mb-0">
                    {{ $t('billing.restricted.button') }}
                  </h5>
                </button>
              </router-link>
            </div>
          </div>
        </div>

      </div>
    </b-modal>
  </div>
</template>
