import { mapState, mapGetters, mapActions } from 'vuex'

export const layoutComputed = {
  ...mapState('layout', {
    layoutType: (state) => state.layoutType,
  })
}

export const layoutMethods = mapActions('layout', ['changeLayoutType'])

export const notificationMethods = mapActions('notification', ['success', 'error', 'clear'])
