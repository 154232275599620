import store from '@/state/store'
import {AccountStatus, SessionLoginState} from '@/enums'

export default [
  {
    path: '',
    name: 'index',
    meta: {
      login_required: false,
      beforeResolve(routeTo, routeFrom, next) {
        next({name: 'dashboard'});
      },
    },
  },
  {
    path: '/dummy',
    name: 'dummy',
    meta: {
      login_required: false,
      beforeResolve(routeTo, routeFrom, next) {
        //next({name: 'dashboard'});
      },
    },
  },
  {
    path: '/upgrade-account',
    name: 'upgrade',
    component: () => import('@/router/view/upgrade'),
    meta: {
      login_required: true
    },
  },
  {
    path: '/authorize/:application_id',
    name: 'api_authorize',
    component: () => import('@/router/authorize/index'),
    meta: {
      login_required: true,
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/router/view/dashboard/index'),
    meta: {
      login_required: true
    },
  },
  /*
  {
    path: '/compute',
    name: 'compute',
    component: () => import('@/router/view/computing/index'),
    meta: {
      login_required: true
    },
  },
  */
  {
    path: '/subscribe',
    name: 'subscribe',
    //component: () => import('@/router/view/subscribe/index'),
    meta: {
      login_required: true,
      beforeResolve(routeTo, routeFrom, next) {
        next({name: 'billing_component', params: {component: 'subscription'}});
      }
    },
  },
  {
    path: '/subscribe/return',
    name: 'subscribe-return',
    component: () => import('@/router/view/subscribe/index'),
    meta: {
      login_required: true,
      beforeResolve(routeTo, routeFrom, next) {
        next({name: 'subscribe'});
      }
    },
  },
  {
    path: '/account',
    name: 'account',
    component: () => import('@/router/view/account/index'),
    meta: {
      login_required: true
    },
  },
  {
    path: '/invoices',
    name: 'invoices',
    //component: () => import('@/router/view/invoices/index'),
    meta: {
      login_required: true,
      beforeResolve(routeTo, routeFrom, next) {
        next({name: 'billing_component', params: {component: 'invoices'}});
      }
    },
  },
  {
    path: '/preferences',
    name: 'preferences',
    component: () => import('@/router/view/preferences/index'),
    meta: {
      login_required: true
    },
  },
  {
    path: '/preferences/server-nav',
    name: 'preferences_nav',
    component: () => import('@/router/view/preferences/serverNav'),
    meta: {
      login_required: true
    },
  },
  {
    path: '/buckets',
    name: 'buckets',
    component: () => import('@/router/view/buckets/index'),
    meta: {
      login_required: true
    },
  },
  {
    path: '/invoice/:invoice_id',
    name: 'invoice_details',
    component: () => import('@/router/view/invoices/details'),
    meta: {
      login_required: true
    },
  },
  {
    path: '/support',
    name: 'support',
    component: () => import('@/router/view/support/index'),
    meta: {
      login_required: true
    },
  },
  /*
  {
    path: '/discord',
    name: 'discord',
    component: () => import('@/router/view/discord/index'),
    meta: {
      login_required: true
    }
  },
  {
    path: '/discord_guild/:guild_id',
    name: 'discord_guild',
    component: () => import('@/router/view/discord/view'),
    meta: {
      login_required: true,
      beforeResolve(routeTo, routeFrom, next) {
        if((!store.getters.getSubscriberStatus() && !store.getters.getServers().length)) return next({'name': 'dashboard'});
        next();
      }
    }
  },
  {
    path: '/discord_ticket/:guild_id/:ticket_id',
    name: 'discord_ticket',
    component: () => import('@/router/view/discord/read'),
    meta: {
      login_required: true
    },
  },
  */
  {
    path: '/support/open',
    name: 'support_open',
    component: () => import('@/router/view/support/open'),
    meta: {
      login_required: true
    },
  },
  {
    path: '/support/:ticket_id',
    name: 'support_read',
    component: () => import('@/router/view/support/read'),
    meta: {
      login_required: true
    },
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('@/router/view/search/index'),
    meta: {
      login_required: true,
      beforeResolve(routeTo, routeFrom, next) {
        if((!store.getters.getSubscriberStatus() && !store.getters.getServers().length)) return next({'name': 'dashboard'});
        next();
      }
    },
  },
  {
    path: '/profile',
    name: 'my_profile',
    meta: {
      login_required: true,
      beforeResolve(routeTo, routeFrom, next) {
        let cftools_id = store.getters.getAccountId();
        next({name: 'profile', params: {cftools_id: cftools_id}});
      }
    }
  },
  {
    path: '/profile/:cftools_id',
    name: 'profile',
    component: () => import('@/router/view/profile/index'),
    meta: {
      login_required: true,
      beforeResolve(routeTo, routeFrom, next) {
        let cftools_id = store.getters.getAccountId();
        if((!store.getters.getSubscriberStatus() && !store.getters.getServers().length)) {
          //if(cftools_id !== routeTo.params.cftools_id) return next({'name': 'dashboard'});
          return next({'name': 'dashboard'});
        }
        next();
      }
    }
  },
  {
    path: '/ban-manager',
    name: 'ban-manager',
    component: () => import('@/router/view/ban-manager/index'),
    meta: {
      login_required: true,
      beforeResolve(routeTo, routeFrom, next) {
        if((!store.getters.getSubscriberStatus() && !store.getters.getServers().length)) return next({'name': 'dashboard'});
        next();
      }
    }
  },
  {
    path: '/ban-manager/:banlist_id',
    name: 'banlist',
    component: () => import('@/router/view/ban-manager/view'),
    meta: {
      login_required: true,
      beforeResolve(routeTo, routeFrom, next) {
        if((!store.getters.getSubscriberStatus() && !store.getters.getServers().length)) return next({'name': 'dashboard'});
        next();
      }
    }
  },
  {
    path: '/watchers',
    name: 'watchers',
    component: () => import('@/router/view/watchers/index'),
    meta: {
      login_required: true,
      beforeResolve(routeTo, routeFrom, next) {
        if((!store.getters.getSubscriberStatus() && !store.getters.getServers().length)) return next({'name': 'dashboard'});
        next();
      }
    }
  },
  /*
  {
    path: '/organizations',
    name: 'organizations',
    component: () => import('@/router/view/organizations/index'),
    meta: {
      login_required: true,
      beforeResolve(routeTo, routeFrom, next) {
        if((!store.getters.getCapabilities()?.organizations && !store.getters.getOrganizations().length && !store.getters.getOrganizationInvites().length)) return next({'name': 'dashboard'});
        next();
      }
    }
  },
  {
    path: '/organizations/:organization_id',
    name: 'organization',
    component: () => import('@/router/view/organizations/view'),
    meta: {
      login_required: true,
      beforeResolve(routeTo, routeFrom, next) {
        // if((!store.getters.getSubscriberStatus() && !store.getters.getOrganizations().length)) return next({'name': 'dashboard'});
        next();
      }
    }
  },
  */
  {
    path: '/teams',
    name: 'teams',
    component: () => import('@/router/view/teams/index'),
    meta: {
      login_required: true,
      beforeResolve(routeTo, routeFrom, next) {
        if((!store.getters.getSubscriberStatus() && !store.getters.getTeams().length && !store.getters.getTeamInvites().length && !store.getters.getServers().length)) return next({'name': 'dashboard'});
        next();
      }
    }
  },
  {
    path: '/teams/:team_id',
    name: 'team',
    component: () => import('@/router/view/teams/view'),
    meta: {
      login_required: true,
      beforeResolve(routeTo, routeFrom, next) {
        if(!store.getters.getSubscriberStatus() && !store.getters.getOrganizations().length && !store.getters.getTeams().length && !store.getters.getServers().length) return next({'name': 'dashboard'});
        routeTo.meta.organization_id = routeFrom.params.organization_id
        next();
      }
    }
  },
  {
    path: '/servers/add',
    name: 'servers_add',
    component: () => import('@/router/view/servers/add'),
    meta: {
      login_required: true
    }
  },
  {
    path: '/server/:server_id/access-restricted',
    name: 'server_restricted',
    component: () => import('@/router/view/servers/restricted'),
    meta: {
      login_required: true,
      beforeResolve(routeTo, routeFrom, next) {
        if((!store.getters.getSubscriberStatus() && !store.getters.getServers().length)) return next({'name': 'dashboard'});

        let server;

        try {
          server = store.getters.getServer(routeTo.params.server_id);
        } catch(e) {
          next({name: 'dashboard'});
        }
        if(server === null) next({name: 'dashboard'});
        if(server.restricted !== null && server.restricted === false) {
          next({name: 'server', params: {server_id: routeTo.params.server_id}});
        }
        next();
      }
    }
  },
  {
    path: '/server/:server_id',
    name: 'server',
    component: () => import('@/router/view/servers/view'),
    meta: {
      login_required: true,
      beforeResolve(routeTo, routeFrom, next) {
        if((!store.getters.getSubscriberStatus() && !store.getters.getServers().length)) return next({'name': 'dashboard'});

        let server;

        try {
          server = store.getters.getServer(routeTo.params.server_id);
        } catch(e) {
          next({name: 'dashboard'});
        }
        if(server === null) next({name: 'dashboard'});
        if(server.restricted !== null && server.restricted === true) {
          console.log(`%c[ROUTER] Routing denied, server restricted [restricted=${server.restricted}]`, 'color:red;');
          next({name: 'server_restricted', params: {server_id: routeTo.params.server_id}});
        }
        next();
      }
    }
  },
  {
    path: '/server/:server_id/:component',
    name: 'server_component',
    component: () => import('@/router/view/servers/view'),
    meta: {
      login_required: true,
      beforeResolve(routeTo, routeFrom, next) {
        if((!store.getters.getSubscriberStatus() && !store.getters.getServers().length)) return next({'name': 'dashboard'});

        let server;

        try {
          server = store.getters.getServer(routeTo.params.server_id);
        } catch(e) {
          next({name: 'dashboard'});
        }
        if(server === null) next({name: 'dashboard'});
        if(server.restricted !== null && server.restricted === true) {
          console.log(`%c[ROUTER] Routing denied, server restricted [restricted=${server.restricted}]`, 'color:red;');
          next({name: 'server_restricted', params: {server_id: routeTo.params.server_id}});
        }
        next();
      }
    }
  },
  {
    path: '/dashboard/:server_id',
    name: 'server-dashboard',
    component: () => import('@/router/view/servers/dashboard'),
    meta: {
      login_required: true,
      beforeResolve(routeTo, routeFrom, next) {
        if((!store.getters.getSubscriberStatus() && !store.getters.getServers().length)) return next({'name': 'dashboard'});

        let server;

        try {
          server = store.getters.getServer(routeTo.params.server_id);
        } catch(e) {
          next({name: 'dashboard'});
        }
        if(server === null) next({name: 'dashboard'});
        if(server.restricted !== null && server.restricted === true) {
          console.log(`%c[ROUTER] Routing denied, server restricted [restricted=${server.restricted}]`, 'color:red;');
          next({name: 'server_restricted', params: {server_id: routeTo.params.server_id}});
        }
        next();
      }
    }
  },
  {
    path: '/billing',
    name: 'billing',
    component: () => import('@/router/view/billing/index'),
    meta: {
      login_required: true
    }
  },
  {
    path: '/billing/approve',
    name: 'billing_approve',
    component: () => import('@/router/view/billing/approve'),
    meta: {
      login_required: true
    }
  },
  {
    path: '/billing/:component',
    name: 'billing_component',
    component: () => import('@/router/view/billing/index'),
    meta: {
      login_required: true
    }
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      auth_required: true,
      beforeResolve(routeTo, routeFrom, next) {
        fetch(process.env.VUE_APP_ROOT_API + 'v1/@me/acsrf-token', {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          }
        })
        .then(data => {
          console.log(data);
          /* Actual request */
          let payload = {
            acsrf_token: data.acsrf_token
          };
          fetch(process.env.VUE_APP_ROOT_API + 'v1/@me/logout', {
            method: 'POST',
            body: JSON.stringify(payload),
            credentials: 'include'
          })
          .then(response => {
            if(response.ok){
              return response.json();
            }
          })
          .then(data => {
            console.log('Logging out...');
            store.commit('actionLogout');
            location.reload();
          })
          .catch(error => {
            console.log(error);
          });
          /* --- */
        })
        .catch(error => {
          console.log(error);
        });
      },
    },
  },
  {
    path: '*',
    name: 'not-found',
    component: () => import('@/router/view/errors/404'),
    meta: {
      auth_required: false
    }
  }
]
