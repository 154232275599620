<style>
.app-search input::placeholder {
  color: #a6b0cf !important;
}
.app-search input {
  color: white !important;
  min-width: 35rem;
}
</style>

<script>
import { mapGetters } from 'vuex'
import {get_acsrf_token, with_acrf_token} from "@/methods";
import {HalfCircleSpinner} from "epic-spinners";
import store from "@/state/store";

/**
 * Nav-bar Component
 */
export default {
  computed: {
    ...mapGetters([
        'getPersonaName',
        'getPersonaAvatar',
        'getServer',
        'getServers',
        'getSubscriberStatus',
        'getPreferences'
    ])
  },
  components: { HalfCircleSpinner },
  created() {
    this.getNotificationCount();
    if((!this.getSubscriberStatus() && !this.getServers().length)) {
      this.redactFeatures = true;
    } else {
      this.redactFeatures = false;
    }
  },
  sockets: {
    notification(data) {
      if(!data || data === null || data === {}) return this.getNotificationCount();
      let watcherEvents = ['user.join','user.leave','user.kicked','user.vac','user.battleye'];
      if(watcherEvents.includes(data.event)) {
        this.handleWatcherEvent(data);
        if(this.getPreferences().audio_alert_watcher) {
          this.audio.audio_alert_watcher.play();
        }
      }
    }
  },
  methods: {
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    async handleWatcherEvent(data) {
      if(this.$route.params.server_id === data.server_id) {
        // Only show toast when current server
        this.$toast.warning('' + this.$t(`watchers.events.${data.event}.content`, data));
        return;
      }

      if(localStorage.displayWatcherPopup && localStorage.displayWatcherPopup === 'false') return;

      let result = await this.$swal({
        icon: 'info',
        title: this.$t('watchers.events.title', data),
        text: this.$t(`watchers.events.${data.event}.content`, data),
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: this.$t('watchers.events.open'),
      });
      if(result.isConfirmed) {
        await this.markNotificationAsRead(data.notification_id);
        if(data.server_id && this.getServer(data.server_id) && this.$route.params.server_id !== data.server_id) {
          console.log('redirecting to server', data.server_id);
          await this.$router.push({name: 'server-dashboard', params: {server_id: data.server_id}});
        } else {
          console.log('redirecting to profile', data.cftools_id);
          await this.$router.push({name: 'profile', params: {cftools_id: data.cftools_id}});
        }
      }
    },
    async markNotificationAsRead(notification_id) {
      with_acrf_token().then((r) => {
        let payload = {
          acsrf_token: r.acsrf_token,
          notification_id: notification_id
        };
        fetch(process.env.VUE_APP_ROOT_API + 'v1/notifications/read', {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        })
          .then(response => {
            if (response.ok) {
              return response.json();
            } else {
              throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
            }
          })
          .then(data => {

          })
          .catch(error => {

          });
      });
    },
    getNotificationCount() {
      fetch(process.env.VUE_APP_ROOT_API + 'v1/notifications/badge', {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          } else {
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          }
        })
        .then(data => {
          this.notificationCount = data.count;
        })
        .catch(error => {
          console.log(error);
        });
    },
    async startGlobalQuery() {
      this.globalQuery.error = false;
      this.globalQuery.results = [];
      if(this.globalQuery.debounceTimer) clearTimeout(this.globalQuery.debounceTimer);
      this.globalQuery.inProgress = true;

      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          identifier: this.globalQuery.identifier
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/global-query`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          let data = await response.json();
          if(data.status) {
            this.globalQuery.results = data.results;
            this.globalQuery.rows = data.results.length;
            this.$refs.globalQueryResults.show();
          } else this.globalQuery.error = true;
        } else {
          if(response.status === 429) {
            this.$swal({
              icon: 'warning',
              text: this.$t('error.server.ratelimit.action'),
              title: this.$t('error.server.ratelimit.title')
            });
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }

      this.globalQuery.inProgress = false;
    },
    async onGlobalQueryIdentifierChange() {
      if(event.keyCode === 13) {
        await this.startGlobalQuery();
        return;
      }
      // TODO: Disabled this for testing as auto-triggering the search is pretty counter intuitive
      // if(this.globalQuery.debounceTimer) clearTimeout(this.globalQuery.debounceTimer);
      // this.globalQuery.debounceTimer = setTimeout(this.startGlobalQuery, 1250);
    },
    onFiltered(filteredItems) {
      this.globalQuery.totalRows = filteredItems.length;
      this.globalQuery.currentPage = 1;
    },
  },
  async mounted() {
    this.audio.audio_alert_watcher.volume = 0.05;
  },
  data() {
    return {
      redactFeatures: true,
      notificationCount: 0,
      audio: {
        audio_alert_watcher: new Audio(require('@/assets/audio/warning.wav')),
      },
      globalQuery: {
        error: false,
        debounceTimer: null,
        inProgress: false,
        identifier: null,
        results: [],
        // table
        filter: null,
        totalRows: 0,
        currentPage: 1,
        filterOn: [],
        sortBy: "cftools_id",
        sortDesc: true,
        fields: [
          {
            key: "user.cftools_id",
            sortable: true,
            label: this.$t('query.results.columns.cftools_id')
          },
          {
            key: "identifier",
            sortable: true,
            label: this.$t('query.results.columns.identifier')
          },
          {
            key: "profile",
            sortable: false,
            label: this.$t('query.results.columns.profile')
          }
        ]
      }
    };
  }
};
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex w-100">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <router-link tag="a" to="/" class="logo logo-light">
            <span class="logo-sm">
              <img src="@/assets/images/logo-light.svg" alt height="24" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/cfcloud.svg" alt width="110" />
            </span>
          </router-link>
        </div>

        <button
          id="vertical-menu-btn"
          type="button"
          class="btn btn-sm px-3 font-size-16 header-item"
          @click="toggleMenu"
        >
          <i class="far fa-bars"></i>
        </button>

        <!--
        TODO: Consult focus group
        <div class="ml-4 d-none d-lg-block">
          <router-link :to="{name: 'my_profile'}" push>
            <button
                type="button"
                class="btn btn-sm px-3 font-size-16 header-item"
            >

              <i class="fad fa-user-circle"></i>
              {{ $t('navbar.profile') }}
            </button>
          </router-link>
        </div>

        <div class="ml-1 d-none d-lg-block" v-if="!redactFeatures">
          <router-link to="/watchers">
            <button
                type="button"
                class="btn btn-sm px-3 font-size-16 header-item"
            >

              <i class="fad fa-eye"></i>
              {{ $t('navbar.watchers') }}
            </button>
          </router-link>
        </div>
        -->

        <div class="ml-1 d-none d-lg-block">
          <router-link to="/support">
            <button
                type="button"
                class="btn btn-sm px-3 font-size-16 header-item"
            >
              <i class="fad fa-map-marker-question"></i>
              {{ $t('navbar.support') }}
            </button>
          </router-link>
        </div>

	      <!--
        <div class="ml-1 d-none d-lg-block">
          <router-link to="/discord">
            <button
                type="button"
                class="btn btn-sm px-3 font-size-16 header-item"
            >
              <i class="fab fa-discord h5 mb-0"></i>
              {{ $t('navbar.discord') }}
            </button>
          </router-link>
        </div>
        -->

        <!-- App Search-->


        <div class="app-search d-none d-lg-inline-block ml-auto mr-auto" v-if="!redactFeatures">
          <div class="position-relative">
            <input
                type="text"
                class="form-control"
                :placeholder="$t('query.placeholder')"
                v-model="globalQuery.identifier"
                v-on:keyup="onGlobalQueryIdentifierChange"
                :disabled="globalQuery.inProgress"
                :class="{'disabled': globalQuery.inProgress, 'is-invalid': globalQuery.error}"
            />
            <template v-if="globalQuery.inProgress">
              <span>
                <half-circle-spinner
                    :animation-duration="1200"
                    :size="22"
                    class="align-middle"
                    style="margin-top: 0.5rem;"
                />
              </span>
            </template>
            <template v-else>
              <span class="fad fa-search" style="cursor: pointer;" v-on:click="startGlobalQuery"></span>
            </template>
          </div>
        </div>


        <!--
        TODO: Find actions to populate mega menu
        <b-dropdown
          variant="black"
          class="dropdown-mega d-none d-lg-block ml-2"
          toggle-class="header-item"
          menu-class="dropdown-megamenu"
        >
          <template v-slot:button-content>
            Actions
            <i class="far fa-chevron-down"></i>
          </template>

          <div class="row">
            <div class="col-sm-8">
              <div class="row">
                <div class="col-md-4">
                  <h5 class="font-size-14 mt-0">XYZ</h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a href="javascript:void(0);">1</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </b-dropdown>
        -->
      </div>


      <div class="d-flex">
        <b-dropdown
          class="d-inline-block d-lg-none ml-2"
          variant="black"
          menu-class="dropdown-menu-lg p-0"
          toggle-class="header-item noti-icon"
          right
        >
          <template v-slot:button-content>
            <i class="fad fa-search"></i>
          </template>

          <div class="p-3">
            <div class="form-group m-0">
              <div class="input-group">
                <input
                    type="text"
                    class="form-control"
                    :placeholder="$t('query.placeholder')"
                    v-model="globalQuery.identifier"
                    v-on:keyup="onGlobalQueryIdentifierChange"
                    :disabled="globalQuery.inProgress"
                    :class="{'disabled': globalQuery.inProgress, 'is-invalid': globalQuery.error}"
                />
                <div class="input-group-append">
                  <button class="btn btn-primary" v-on:click="startGlobalQuery">
                    <template v-if="globalQuery.inProgress">
                      <half-circle-spinner
                          :animation-duration="1200"
                          :size="16"
                          class="align-middle"
                      />
                    </template>
                    <template v-else>
                      <i class="fad fa-search"></i>
                    </template>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </b-dropdown>

        <!--
        TODO: Team select for a selective view
        <b-dropdown
          class="d-lg-inline-block noti-icon"
          menu-class="dropdown-menu-lg"
          right
          toggle-class="header-item"
          variant="black"
        >
          <template v-slot:button-content>
            <i class="fad fa-layer-group "></i>
            <span class="d-none d-xl-inline-block ml-1">{{ $t('navbar.teams')}}</span>
          </template>

          <div class="px-lg-2">
            <div class="row no-gutters">
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">

                  <span>Team</span>
                </a>
              </div>
            </div>
          </div>
        </b-dropdown>
        -->
        <div class="dropdown b-dropdown btn-group d-inline-block">
          <button
              type="button"
              class="btn header-item noti-icon right-bar-toggle toggle-right"
              @click="toggleRightSidebar"
          >
            <i class="fad fa-bell toggle-right"></i>
            <template v-if="notificationCount > 0">
              <span class="badge badge-danger badge-pill">
                {{ notificationCount }}
              </span>
            </template>

          </button>
        </div>

        <!--
        <b-dropdown
          right
          menu-class="dropdown-menu-lg p-0"
          toggle-class="header-item noti-icon"
          variant="black"
        >
          <template v-slot:button-content>
            <i class="fad fa-bell"></i>
            <span
              class="badge badge-danger badge-pill"
            >1</span>
          </template>

          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">{{ $t('navbar.dropdown.notification.text')}}</h6>
              </div>
              <div class="col-auto">
                <a href="#" class="small">{{ $t('navbar.dropdown.notification.subtext')}}</a>
              </div>
            </div>
          </div>
          <simplebar style="max-height: 230px;">
            <a href="javascript: void(0);" class="text-reset notification-item">
              <div class="media">
                <div class="avatar-xs mr-3">
                  <span class="avatar-title bg-primary rounded-circle font-size-16">
                    <i class="bx bx-cart"></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1">{{ $t('navbar.dropdown.notification.order.title')}}</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">{{ $t('navbar.dropdown.notification.order.text')}}</p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      {{ $t('navbar.dropdown.notification.order.time')}}
                    </p>
                  </div>
                </div>
              </div>
            </a>
            <a href="javascript: void(0);" class="text-reset notification-item">
              <div class="media">
                <img
                  src="@/assets/images/users/avatar-3.jpg"
                  class="mr-3 rounded-circle avatar-xs"
                  alt="user-pic"
                />
                <div class="media-body">
                  <h6 class="mt-0 mb-1">{{ $t('navbar.dropdown.notification.james.title')}}</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">{{ $t('navbar.dropdown.notification.james.text')}}</p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      {{ $t('navbar.dropdown.notification.james.time')}}
                    </p>
                  </div>
                </div>
              </div>
            </a>
            <a href="javascript: void(0);" class="text-reset notification-item">
              <div class="media">
                <div class="avatar-xs mr-3">
                  <span class="avatar-title bg-success rounded-circle font-size-16">
                    <i class="bx bx-badge-check"></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1">{{ $t('navbar.dropdown.notification.item.title')}}</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">{{ $t('navbar.dropdown.notification.item.text')}}</p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      {{ $t('navbar.dropdown.notification.item.time')}}
                    </p>
                  </div>
                </div>
              </div>
            </a>
            <a href="javascript: void(0);" class="text-reset notification-item">
              <div class="media">
                <img
                  src="@/assets/images/users/avatar-4.jpg"
                  class="mr-3 rounded-circle avatar-xs"
                  alt="user-pic"
                />
                <div class="media-body">
                  <h6 class="mt-0 mb-1">{{ $t('navbar.dropdown.notification.salena.title')}}</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">{{ $t('navbar.dropdown.notification.salena.text')}}</p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      {{ $t('navbar.dropdown.notification.salena.time')}}
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </simplebar>
          <div class="p-2 border-top">
            <a class="btn btn-sm btn-light btn-block text-center" href="javascript:void(0)">
              <i class="mdi mdi-arrow-down-circle mr-1"></i>
              {{ $t('navbar.dropdown.notification.button')}}
            </a>
          </div>
        </b-dropdown>
        -->

        <b-dropdown right variant="black" toggle-class="header-item">
          <template v-slot:button-content>
            <img
                id="app-avatar"
                class="rounded-circle header-profile-user"
                :src="getPersonaAvatar()"
                alt="User profile picture"
            />
            <span class="d-none d-xl-inline-block ml-1">{{ getPersonaName() }}</span>
          </template>
          <!-- item-->
          <b-dropdown-item to="/support">
            <i class="fad fa-map-marker-question font-size-16 align-middle mr-1"></i>
            {{ $t('navbar.support') }}
          </b-dropdown-item>
          <b-dropdown-item to="/preferences">
            <i class="fad fa-user-cog font-size-16 align-middle mr-1"></i>
            {{ $t('navbar.preferences') }}
          </b-dropdown-item>
          <template v-if="!redactFeatures">
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item to="/profile">
              <i class="fad fa-user-circle font-size-16 align-middle mr-1"></i>
              {{ $t('navbar.profile') }}
            </b-dropdown-item>
            <b-dropdown-item to="/watchers">
              <i class="fad fa-eye font-size-16 align-middle mr-1"></i>
              {{ $t('navbar.watchers') }}
            </b-dropdown-item>
            <b-dropdown-item to="/buckets">
              <i class="fad fa-fill font-size-16 align-middle mr-1"></i>
              {{ $t('navbar.buckets') }}
            </b-dropdown-item>
          </template>
          <b-dropdown-divider></b-dropdown-divider>
          <router-link to="/logout" class="dropdown-item text-danger">
            <i class="fad fa-sign-out font-size-16 align-middle mr-1 text-danger"></i>
            {{ $t('navbar.logout') }}
          </router-link>
        </b-dropdown>
      </div>
    </div>
    <b-modal size="lg" ref="globalQueryResults" :hide-footer="true" :title="$t('query.results.title')">
      <template v-if="globalQuery.results.length === 0">
        <div class="row">
          <div class="col">
            <h4 class="text-center text-uppercase text-muted mt-4 mb-4">
              {{ $t('query.results.none') }}
            </h4>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="row">
          <div class="col">
            <div class="row">
              <div class="col-lg-12 col-sm-12">
                <div id="tickets-table_filter" class="dataTables_filter">
                  <label class="d-inline-flex align-items-center">
                    {{ $t('query.results.refine') }}:
                    <b-form-input v-model="globalQuery.filter" type="search" class="mw-100 form-control form-control-sm ml-2"></b-form-input>
                  </label>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <b-table :items="globalQuery.results" :fields="globalQuery.fields" responsive="sm" :per-page="10" :current-page="globalQuery.currentPage" :sort-by.sync="globalQuery.sortBy" :sort-desc.sync="globalQuery.sortDesc" :filter="globalQuery.filter" :filter-included-fields="globalQuery.filterOn" @filtered="onFiltered">
                <template #cell(cftools_id)="data">
                  <b>{{ data.item.cftools_id }}</b>
                </template>
                <template #cell(identifier)="data">
                  <b>{{ data.item.identifier }}</b>
                </template>
                <template #cell(profile)="data">
                  <router-link :to="{name: 'profile', params: {cftools_id: data.item.user.cftools_id}}">
                    <img
                        :src="data.item.user.avatar"
                        alt="profile picture"
                        class="rounded-circle avatar-xs"
                        v-if="data.item.user.avatar"
                        target="_blank"
                    >
                    {{ data.item.user.display_name }}
                    <small>
                      <i class="fal fa-external-link-alt" />
                    </small>
                  </router-link>
                </template>
              </b-table>
              <div class="dataTables_paginate paging_simple_numbers float-right">
                <ul class="pagination pagination-rounded mb-0">
                  <b-pagination v-model="globalQuery.currentPage" :total-rows="globalQuery.rows" :per-page="10"></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-modal>
  </header>
</template>
