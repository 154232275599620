export const refreshPageMixin = {
    data() {
        return {
            currentHash: '{{POST_BUILD_ENTERS_HASH_HERE}}',
            localHash: localStorage.getItem('appVersion'),
            hashChanged: false,
            newHash: ''
        }
    },
    methods: {
        initVersionCheck(frequency = 1000 * 60 * 2) {
            localStorage.appVersion = this.currentHash;
            setInterval(() => {
                this.checkVersion();
            }, frequency);
        },
        async checkVersion(ref) {
            try {
                const fileResponse = await fetch(`${window.location.origin}/version.json?t=${new Date().getTime()}`);
                let data = await fileResponse.json();
                this.newHash = data.hash;
                this.hashChanged = this.hasHashChanged(this.currentHash, this.newHash);
            } catch (error) {
                this.loading = false;
                if (!error.response) {
                    this.errorStatus = 'Error: Network Error'
                } else {
                    this.errorStatus = error.response.data.message;
                }
            }
        },
        hasHashChanged(currentHash, newHash) {
            if (!currentHash) {
                return true;
            }

            return currentHash !== newHash || currentHash !== this.localHash;
        },
        reloadApp() {
            this.currentHash = this.newHash;
            window.location.reload();
        }
    }
};